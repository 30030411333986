import React from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router';
import { hasRoleSelector } from '../ducks';

const RoleRoute = ({ hasRole, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      hasRole ? <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    }
  />
);

RoleRoute.propTypes = {
  hasRole: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  location: RouterPropTypes.location.isRequired,
};

const mapStateToProps = (state, { roles }) => ({
  hasRole: hasRoleSelector(state.account.login, roles),
});

export default withRouter(connect(mapStateToProps)(RoleRoute));
