import styled from 'styled-components';
import theme from '../../core/styles/theme';

export const LocationInfoWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 3px;
`;

export const SiteName = styled.div`
  display: flex;
  min-width: 130px;
  color: ${theme.grayText};
  line-height: 16px;
  align-items: center;
  height: 100%;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const ServiceName = styled.div`
  line-height: 16px;
  font-size: 12px;
  padding: 2px 0;
`;
