import store from '../../store';
import { createNotification as doCreateNotification } from '../ducks';

const DEFAULT_AUTO_CLOSE_TIME = 6000;

const createNotification = (message, color, autoClose) =>
  store.dispatch(doCreateNotification(message, color, autoClose));

export const createSuccessNotification = (message, autoClose = DEFAULT_AUTO_CLOSE_TIME) =>
  createNotification(message, 'success', autoClose);

export const createErrorNotification = (message, autoClose = DEFAULT_AUTO_CLOSE_TIME) =>
  createNotification(message, 'alert', autoClose);
