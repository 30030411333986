import styled from 'styled-components';

const CheckmarkImage = styled.div`
  width: 40px;
  height: 40px;
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSIgZmlsbC1ydWxlPSJub256ZXJvIiBzdHJva2U9IiMwMEE1OTkiIHN0cm9rZS13aWR0aD0iMS41IiBmaWxsPSJub25lIj48Y2lyY2xlIGN4PSIxOSIgY3k9IjE5IiByPSIxOSIvPjxwYXRoIGQ9Ik0xMy45ODcgMTlsNC4wMjYgMy41IDYuNDg3LTciLz48L2c+PC9zdmc+');
  background-size: cover;
`;

export default CheckmarkImage;
