import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import uuid from 'uuid';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import { isPristine, formValueSelector, initialize, reset } from 'redux-form';
import { get } from 'lodash-es';
import { Panel, Button, PanelSection, PanelSectionHeader, PanelSectionTitle } from '../../../core/components/styled';
import { serviceTypesSelector, serviceOptionsSelector } from '../../../common/ducks';
import { PageHeader, PageTitle, PageActions } from '../../../common/components/styled';
import {
  addService,
  updateService,
  updateServicePrice,
  deleteService,
  updateCustomer,
  saveQuote,
  locationByServiceIdSelector,
  locationsWithServicesSelector,
  serviceCountSelector,
} from '../../ducks';
import { CustomerEditorModal } from '../modals';
import { ServiceEditorForm } from '../forms';
import serviceEditorFormInitialValuesSelector from '../../services/serviceEditorFormInitialValuesSelector';
import serviceEditorFormResetValuesSelector from '../../services/serviceEditorFormResetValuesSelector';
import ServiceSummary from '../ServiceSummary';
import { NonMobile } from '../../../common/components/Responsive';

class ServiceEditorPage extends PureComponent {
  state = { isCustomerModalOpen: false };

  openCustomerModal = () => {
    this.setState({ isCustomerModalOpen: true });
  };

  closeCustomerModal = () => {
    this.setState({ isCustomerModalOpen: false });
  };

  addLocation = () => {
    const { match, push } = this.props;
    push(`/quote/${match.params.quoteId}/choose-provider`);
  };

  onFormSubmit = async formData => {
    const {
      match,
      locationId,
      formResetValues,
      formInitialValues,
      addService,
      updateService,
      updateServicePrice,
      saveQuote,
      initialize,
      reset,
      push,
    } = this.props;

    const { quoteId, serviceId } = match.params;

    initialize('serviceEditor', formResetValues, {
      keepDirty: false,
      keepSubmitSucceeded: false,
    });

    reset('serviceEditor');

    const { serviceType } = formData.service;
    const service = {
      serviceType,
      ...formData.service[serviceType],
    };

    if (serviceId) {
      updateService(serviceId, service);
      if (formInitialValues.service[serviceType].price.pickup !== formData.service[serviceType].price.pickup) {
        updateServicePrice(serviceId, formData.service[serviceType].price.pickup);
      }
    } else {
      addService(locationId, uuid(), service);
    }

    await saveQuote();

    if (serviceId) {
      push(`/quote/${quoteId}/locations/${locationId}/services`);
    }
  };

  render() {
    const { isCustomerModalOpen } = this.state;

    const {
      isSaving,
      isFormPristine,
      quoteId,
      locationId,
      serviceCount,
      serviceTypes,
      serviceType,
      serviceOptions,
      containerSize,
      formInitialValues,
      match,
    } = this.props;

    const { serviceId } = match.params;

    return (
      <>
        <PageHeader>
          <NonMobile>
            <PageTitle>Quote</PageTitle>
          </NonMobile>
          <PageActions>
            <Button line margin="no small no no" color="primary" disabled={isSaving} onClick={this.addLocation}>
              Add location
            </Button>

            <Button
              color="primary"
              disabled={!isFormPristine || !serviceCount || isSaving}
              onClick={this.openCustomerModal}
            >
              Next
            </Button>
          </PageActions>
        </PageHeader>

        <Panel horizontal tabletVertical isLoading={isSaving}>
          <PanelSection size="50%" padding="medium medium xLarge" border="right">
            <ServiceEditorForm
              initialValues={formInitialValues}
              serviceTypes={serviceTypes}
              serviceType={serviceType}
              serviceOptions={serviceOptions}
              containerSize={containerSize}
              isNew={!serviceId}
              onSubmit={this.onFormSubmit}
            />
          </PanelSection>

          <PanelSection size="50%" padding="medium medium small">
            <PanelSectionHeader>
              <PanelSectionTitle>Summary</PanelSectionTitle>
            </PanelSectionHeader>

            <ServiceSummary
              showPrice
              smallCard
              quoteId={quoteId}
              selectedLocationId={locationId}
              selectedServiceId={serviceId}
            />
          </PanelSection>
        </Panel>

        {isCustomerModalOpen && <CustomerEditorModal quoteId={quoteId} closeModal={this.closeCustomerModal} />}
      </>
    );
  }
}

ServiceEditorPage.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  isFormPristine: PropTypes.bool,
  quoteId: PropTypes.string.isRequired,
  locationId: PropTypes.string,
  serviceCount: PropTypes.number.isRequired,
  serviceTypes: PropTypes.arrayOf(PropTypes.string),
  serviceType: PropTypes.string,
  serviceOptions: PropTypes.object,
  containerSize: PropTypes.number,
  formInitialValues: PropTypes.object,
  formResetValues: PropTypes.object,
  match: RouterPropTypes.match.isRequired,
  addService: PropTypes.func.isRequired,
  updateService: PropTypes.func.isRequired,
  updateServicePrice: PropTypes.func.isRequired,
  saveQuote: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
};

ServiceEditorPage.defaultProps = {
  isFormPristine: undefined,
  locationId: undefined,
  serviceType: undefined,
  serviceTypes: undefined,
  serviceOptions: undefined,
  containerSize: undefined,
  formInitialValues: undefined,
  formResetValues: undefined,
};

const serviceFormSelector = formValueSelector('serviceEditor');
const locationsFilter = { mustHaveAddress: true };

const mapStateToProps = (state, ownProps) => {
  const { id: quoteId } = state.quote.quote;
  const { serviceId } = ownProps.match.params;

  const locationId = serviceId
    ? get(locationByServiceIdSelector(state.quote.quote, serviceId), 'id')
    : ownProps.match.params.locationId;

  const locations = locationsWithServicesSelector(state.quote.quote, locationsFilter);
  const serviceCount = serviceCountSelector(state.quote.quote);
  const zipCode = serviceFormSelector(state, 'location.address.zip');
  const serviceType = serviceFormSelector(state, 'service.serviceType');
  const wasteType = serviceFormSelector(state, `service.${serviceType}.wasteType`);
  const containerSize = serviceFormSelector(state, `service.${serviceType}.containerSize`) || undefined;
  const incumbentRebateProgram =
    serviceFormSelector(state, `service.${serviceType}.incumbentRebateProgram`) || undefined;
  const recurringFrequency = serviceFormSelector(state, `service.${serviceType}.recurringFrequency`) || undefined;

  const serviceOptions = serviceOptionsSelector(state.common.serviceOptions, zipCode, serviceType, {
    wasteType,
    containerSize,
    recurringFrequency,
  });

  return {
    quoteId,
    locationId,
    locations,
    serviceCount,
    serviceType,
    serviceOptions,
    containerSize,
    incumbentRebateProgram,
    isSaving: state.quote.quote.isSaving,
    isFormPristine: isPristine('serviceEditor')(state),
    serviceTypes: serviceTypesSelector(state.common.serviceOptions, zipCode),
    formInitialValues: serviceEditorFormInitialValuesSelector(state.quote.quote, locationId, serviceId),
    formResetValues: serviceEditorFormResetValuesSelector(state.quote.quote, locationId),
  };
};

const mapDispatchToProps = {
  addService,
  updateService,
  updateServicePrice,
  deleteService,
  updateCustomer,
  saveQuote,
  initialize,
  reset,
  push,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ServiceEditorPage),
);
