import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Resolver } from '../../../core/components';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { loadAccountProfile } from '../../ducks';
import AccountProfileModal from './AccountProfileModal';

class AccountProfileModalResolver extends PureComponent {
  resolve = () => {
    const { loadAccountProfile } = this.props;
    return loadAccountProfile();
  };

  render() {
    const { closeModal } = this.props;
    return (
      <Resolver
        successComponent={AccountProfileModal}
        successProps={{ closeModal }}
        loadingComponent={PageLoadingOverlay}
        resolve={this.resolve}
      />
    );
  }
}

AccountProfileModalResolver.propTypes = {
  loadAccountProfile: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = { loadAccountProfile };

export default connect(
  undefined,
  mapDispatchToProps,
)(AccountProfileModalResolver);
