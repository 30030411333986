import styled, { css } from 'styled-components';
import { sizeMapper, media } from '../../../utils/styles';

export const SummaryResponsiveInfoPanel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: ${props => sizeMapper(props.margin, 'no no sMedium')};

  ${media.phone`
    justify-content: normal;
    flex-direction: column;
    flex-flow: column-reverse;
  `}
`;

export const SummaryInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${media.phone`
     flex-direction: row;
     justify-content: space-between;
     width: 100%;
     border-bottom: 1px solid ${props => props.theme.grayLight};
     padding-bottom: 20px;
  `}

  ${props =>
    props.marginBottom &&
    css`
      ${media.phone`
        margin-bottom: 20px;
      `}
    `}
`;

export const SummaryPercentage = styled.div`
  text-align: right;
  display: block;
  margin-bottom: 5px;
  font-size: 26px;
  color: ${props => props.theme.brandPrimary};

  ${media.phone`
     font-size: 18px;
     margin: 0;
  `}
`;

export const SummaryAction = styled.div`
  margin-top: 15px;

  ${media.phone`
     margin-top: 3px;
  `}
`;

export const SummaryTitle = styled.div`
  font-size: 20px;
  margin-bottom: 20px;

  ${media.phone`
     font-size: 18px;
  `}

  ${props =>
    props.noMobileMargin &&
    css`
      ${media.phone`
        margin: 0;
      `}
    `}
`;
