import update from 'immutability-helper';
import { createSelector } from 'reselect';
import { intersection, castArray, identity } from 'lodash-es';
import { PARTNER_SUPER_ADMIN, PARTNER_ADMIN } from '../constants';
import {
  login as doLogin,
  logout as doLogout,
  refreshAuthToken as doRefreshAuthToken,
  getUser,
  getRefreshToken,
} from '../services/auth';

// Actions
const START_LOGIN = 'account/login/START_LOGIN';
const COMPLETE_LOGIN = 'account/login/COMPLETE_LOGIN';
const FAIL_LOGIN = 'account/login/FAIL_LOGIN';
const COMPLETE_LOGOUT = 'account/login/COMPLETE_LOGOUT';
const START_REFRESH_AUTH_TOKEN = 'account/login/START_REFRESH_TOKEN';
const COMPLETE_REFRESH_AUTH_TOKEN = 'account/login/COMPLETE_REFRESH_TOKEN';
const RESET = 'account/login/RESET';

// Initial state
const user = getUser();
const canRefreshAuthToken = !!getRefreshToken();
const initialState = {
  isLoggingIn: false,
  isLoggedIn: !!user,
  isLoginFailed: false,
  isRefreshingAuthToken: false,
  canRefreshAuthToken,
  user,
  errorMessage: '',
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOGIN:
      return update(state, {
        $merge: {
          isLoginFailed: false,
          isLoggingIn: true,
          isLoggedIn: false,
        },
      });

    case COMPLETE_LOGIN:
      return update(state, {
        $merge: {
          isLoginFailed: false,
          isLoggingIn: false,
          isLoggedIn: true,
          user: action.user,
        },
      });

    case FAIL_LOGIN:
      return update(state, {
        $merge: {
          isLoginFailed: true,
          isLoggingIn: false,
          isLoggedIn: false,
          errorMessage:
            action.error.response.status === 422
              ? action.error.response && action.error.response.data.exceptionMessage
              : 'Invalid login credentials',
        },
      });

    case COMPLETE_LOGOUT:
      return update(state, {
        $merge: {
          isLoginFailed: false,
          isLoggingIn: false,
          isLoggedIn: false,
          user: undefined,
        },
      });

    case START_REFRESH_AUTH_TOKEN:
      return update(state, {
        $merge: {
          isRefreshingAuthToken: true,
        },
      });

    case COMPLETE_REFRESH_AUTH_TOKEN:
      return update(state, {
        $merge: {
          isRefreshingAuthToken: false,
          isLoggedIn: true,
          user: action.user,
        },
      });

    case RESET:
      return update(state, {
        $merge: {
          isLoginFailed: false,

          isLoggingIn: false,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLogin = () => ({
  type: START_LOGIN,
});

const completeLogin = user => ({
  type: COMPLETE_LOGIN,
  user,
});

const failLogin = error => ({
  type: FAIL_LOGIN,
  error,
});

const completeLogout = () => ({
  type: COMPLETE_LOGOUT,
});

const startRefreshAuthToken = () => ({
  type: START_REFRESH_AUTH_TOKEN,
});

const completeRefreshAuthToken = user => ({
  type: COMPLETE_REFRESH_AUTH_TOKEN,
  user,
});

export const login = (email, password) => dispatch => {
  dispatch(startLogin());
  const loginPromise = doLogin(email, password);
  loginPromise
    .then(user => {
      dispatch(completeLogin(user));
    })
    .catch(error => dispatch(failLogin(error)));
  return loginPromise;
};

export const logout = () => dispatch => {
  doLogout();
  dispatch(completeLogout());
};

export const refreshAuthToken = () => async dispatch => {
  dispatch(startRefreshAuthToken());
  const user = await doRefreshAuthToken();
  dispatch(completeRefreshAuthToken(user));
};

export const resetLogin = () => ({
  type: RESET,
});

// Selectors
const hasRole = (loginState, roles) =>
  !!loginState.user && !!intersection(loginState.user.roles, castArray(roles)).length;

const isPartnerSuperAdmin = loginState => hasRole(loginState, PARTNER_SUPER_ADMIN);
const isPartnerAdmin = loginState => hasRole(loginState, PARTNER_ADMIN);
const isPhoneNumberVerified = loginState => loginState.user && loginState.user.phoneConfirmed;

export const hasRoleSelector = createSelector(
  hasRole,
  identity,
);

export const isPartnerSuperAdminSelector = createSelector(
  isPartnerSuperAdmin,
  identity,
);

export const isPartnerAdminSelector = createSelector(
  isPartnerAdmin,
  identity,
);

export const isPhoneNumberVerifiedSelector = createSelector(
  isPhoneNumberVerified,
  identity,
);
