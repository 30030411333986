import update from 'immutability-helper';
import {
  loadZipcodesAvailability as doLoadZipcodesAvailability,
  loadZipcodeDetails as doLoadZipcodeDetails,
} from '../services/marketAvailability';

// Actions
const START_LOAD = 'marketConfiguration/marketAvailability/START_LOAD';
const COMPLETE_LOAD = 'marketConfiguration/marketAvailability/COMPLETE_LOAD';
const FAIL_LOAD = 'marketConfiguration/marketAvailability/FAIL_LOAD';
const START_LOAD_ZIP_DETAILS = 'marketConfiguration/marketAvailability/START_LOAD_ZIP_DETAILS';
const COMPLETE_LOAD_ZIP_DETAILS = 'marketConfiguration/marketAvailability/COMPLETE_LOAD_ZIP_DETAILS';
const FAIL_LOAD_ZIP_DETAILS = 'marketConfiguration/marketAvailability/FAIL_LOAD_ZIP_DETAILS';
const RESET = 'marketConfiguration/marketAvailability/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isLoadingZipDetails: false,
  marketAvailabilityZips: undefined,
  zipcodeDetails: {},
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, { $merge: { isLoading: true } });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          marketAvailabilityZips: action.marketAvailabilityZips,
        },
      });

    case FAIL_LOAD:
      return update(state, { $merge: { isLoading: false } });

    case START_LOAD_ZIP_DETAILS:
      return update(state, { $merge: { isLoadingZipDetails: true } });

    case COMPLETE_LOAD_ZIP_DETAILS:
      return update(state, {
        $merge: {
          isLoadingZipDetails: false,
          zipcodeDetails: action.zipcodeDetails,
        },
      });

    case FAIL_LOAD_ZIP_DETAILS:
      return update(state, { $merge: { isLoadingZipDetails: false } });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = marketAvailabilityZips => ({
  type: COMPLETE_LOAD,
  marketAvailabilityZips,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startLoadZipDetails = () => ({
  type: START_LOAD_ZIP_DETAILS,
});

const completeLoadZipDetails = zipcodeDetails => ({
  type: COMPLETE_LOAD_ZIP_DETAILS,
  zipcodeDetails,
});

const failLoadZipDetails = () => ({
  type: FAIL_LOAD_ZIP_DETAILS,
});

export const loadZipcodesAvailability = () => dispatch => {
  dispatch(startLoad());
  const loadZipcodesAvailabilityPromise = doLoadZipcodesAvailability();
  loadZipcodesAvailabilityPromise
    .then(marketAvailabilityZips => dispatch(completeLoad(marketAvailabilityZips)))
    .catch(() => dispatch(failLoad()));

  return loadZipcodesAvailabilityPromise;
};

export const loadZipcodeDetails = zipCode => dispatch => {
  dispatch(startLoadZipDetails());
  const loadZipcodeDetailsPromise = doLoadZipcodeDetails(zipCode);
  loadZipcodeDetailsPromise
    .then(zipcodeDetails => dispatch(completeLoadZipDetails(zipcodeDetails)))
    .catch(() => dispatch(failLoadZipDetails()));

  return loadZipcodeDetailsPromise;
};

export const resetMarketAvailability = () => ({
  type: RESET,
});
