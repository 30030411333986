import React from 'react';
import { Switch, Redirect } from 'react-router';
import { PARTNER_ADMIN, PARTNER_MANAGER, PARTNER_USER } from '../../account/constants';
import { AuthRoute, RoleRoute } from '../../account/components';
import { ServiceProviderPage, LocationEditorPage } from './pages';
import ExistingQuoteRouterResolver from './ExistingQuoteRouterResolver';

const QuoteRouter = () => (
  <Switch>
    <RoleRoute
      roles={[PARTNER_ADMIN, PARTNER_MANAGER, PARTNER_USER]}
      exact
      path="/quote/new"
      component={ServiceProviderPage}
    />
    <RoleRoute
      roles={[PARTNER_ADMIN, PARTNER_MANAGER, PARTNER_USER]}
      exact
      path="/quote/locations/new"
      component={LocationEditorPage}
    />
    <AuthRoute path="/quote/:quoteId" component={ExistingQuoteRouterResolver} />
    <Redirect exact path="/quote" to="/quote/new" />
    <Redirect to="/opportunities" />
  </Switch>
);

export default QuoteRouter;
