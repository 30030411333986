import React from 'react';
import PropTypes from 'prop-types';
import { DesktopNavBarContainer, DesktopNavBarItemsContainer, DesktopNavBarLogo } from '../styled';
import DesktopNavBarItem from './DesktopNavBarItem';
import NavigationItems from './NavigationItems';
import DesktopNavBarSettings from './DesktopNavBarSettings';
import { defaultItem } from '../../navigation/navigationItems';

const DesktopNavBar = ({ username, isPartnerSuperAdmin, openUserProfileModal }) => (
  <DesktopNavBarContainer>
    <DesktopNavBarLogo to={defaultItem.to} />
    <DesktopNavBarItemsContainer>
      <NavigationItems ItemComponent={DesktopNavBarItem} />
    </DesktopNavBarItemsContainer>
    <DesktopNavBarSettings
      username={username}
      isPartnerSuperAdmin={isPartnerSuperAdmin}
      openUserProfileModal={openUserProfileModal}
    />
  </DesktopNavBarContainer>
);

DesktopNavBar.propTypes = {
  isPartnerSuperAdmin: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
  openUserProfileModal: PropTypes.func.isRequired,
};

export default DesktopNavBar;
