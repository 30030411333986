import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { PanelSection } from '../../../core/components/styled';
import { DocumentTitle } from '../../../common/components';
import { getQueryParams } from '../../../utils/services/queryParams';
import { login, acceptInvitation, resetAcceptInvitation } from '../../ducks';
import { AcceptInvitationForm } from '../forms';

class AcceptInvitationPage extends PureComponent {
  componentWillUnmount() {
    const { resetAcceptInvitation } = this.props;
    resetAcceptInvitation();
  }

  onFormSubmit = ({ password }) => {
    const { acceptInvitation, location, login, push } = this.props;
    const { code, email } = getQueryParams(location.search);
    acceptInvitation(code, email, password)
      .then(async () => {
        await login(email, password);
        createSuccessNotification(`Password succesfully set`);
        push('/opportunities');
      })
      .catch(err => err.response.status !== 422 && createErrorNotification('Something went wrong. Please try again'));
  };

  render() {
    const { isAcceptingInvitation, errorMessage, isAcceptInvitationFailed } = this.props;
    return (
      <>
        <DocumentTitle>Set up password</DocumentTitle>

        <PanelSection isLoading={isAcceptingInvitation}>
          <AcceptInvitationForm
            onSubmit={this.onFormSubmit}
            errorMessage={errorMessage}
            isAcceptInvitationFailed={isAcceptInvitationFailed}
          />
        </PanelSection>
      </>
    );
  }
}

AcceptInvitationPage.propTypes = {
  isAcceptingInvitation: PropTypes.bool.isRequired,
  acceptInvitation: PropTypes.func.isRequired,
  resetAcceptInvitation: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  location: RouterPropTypes.location.isRequired,
  isAcceptInvitationFailed: PropTypes.any.isRequired,
  errorMessage: PropTypes.any.isRequired,
};

const mapStateToProps = state => ({
  acceptInvitation: state.account.acceptInvitation,
  isAcceptInvitationFailed: state.account.acceptInvitation.isAcceptInvitationFailed,
  errorMessage: state.account.acceptInvitation.errorMessage,
});

const mapDispatchToProps = {
  acceptInvitation,
  resetAcceptInvitation,
  login,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AcceptInvitationPage);
