import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import { sumBy, size } from 'lodash-es';
import { Panel, Message, TableRow, TableCell, Text } from '../../../core/components/styled';
import { Table, Pagination } from '../../../core/components';
import { getQueryParams, createUrl } from '../../../utils/services/queryParams';
import { currency, localDate } from '../../../utils/services/formatter';
import { SuperAdminGuard } from '../../../account/components';
import { isPartnerSuperAdminSelector } from '../../../account/ducks';
import { QUOTED } from '../../../quote/constants';
import { loadOpportunities, resetOpportunities, opportunitiesWithMontlyQuotedPricesSelector } from '../../ducks';
import { getUser } from '../../../account/services/auth';

const LIMIT_PER_PAGE = 20;

const QuotedOpportunitiesTableRow = ({
  uid,
  lastEditDate,
  partnerName,
  partnerUserFirstName,
  partnerUserLastName,
  customerEmail,
  customerPhone,
  contactName,
  locations,
  currentSpending,
  monthlyQuotedPrice,
  tableCellWidths,
  push,
  exContractExpireDate,
  hasIncumbentRebateProgram,
  followUpDate,
}) => (
  <TableRow onClick={() => push(`/opportunities/${uid}/quoted`)}>
    <TableCell smallPadding width={tableCellWidths[0]}>
      {localDate(lastEditDate)}
    </TableCell>
    <SuperAdminGuard>
      <TableCell smallPadding width={tableCellWidths[1]}>
        {partnerName}
      </TableCell>
    </SuperAdminGuard>
    <TableCell smallPadding width={tableCellWidths[8]}>
      {partnerUserFirstName} {partnerUserLastName}
    </TableCell>
    <TableCell smallPadding width={tableCellWidths[2]}>
      <span>{contactName}</span>
      <span>{customerEmail}</span>
      <span>{customerPhone}</span>
    </TableCell>
    <TableCell smallPadding width={tableCellWidths[3]}>
      {locations.map(location => (
        <span key={location.uid}>{location.businessName}</span>
      ))}
    </TableCell>
    <TableCell smallPadding width={tableCellWidths[4]} align="center">
      {size(locations)}
    </TableCell>
    <TableCell smallPadding width={tableCellWidths[5]} align="center">
      <Text block>{sumBy(locations, ({ services }) => size(services))}</Text>
    </TableCell>
    {!hasIncumbentRebateProgram && (
      <TableCell smallPadding width={tableCellWidths[6]} align="center">
        {currency(currentSpending)}
      </TableCell>
    )}
    <TableCell smallPadding width={tableCellWidths[7]}>
      {currency(monthlyQuotedPrice)}
    </TableCell>
    <TableCell smallPadding width={tableCellWidths[9]}>
      {followUpDate ? localDate(followUpDate) : '-'}
    </TableCell>
    <TableCell smallPadding width={tableCellWidths[10]}>
      {exContractExpireDate ? localDate(exContractExpireDate) : '-'}
    </TableCell>
  </TableRow>
);

QuotedOpportunitiesTableRow.propTypes = {
  uid: PropTypes.string.isRequired,
  lastEditDate: PropTypes.string.isRequired,
  exContractExpireDate: PropTypes.string,
  followUpDate: PropTypes.string,
  partnerName: PropTypes.string,
  partnerUserFirstName: PropTypes.string,
  partnerUserLastName: PropTypes.string,
  contactName: PropTypes.string,
  customerEmail: PropTypes.string,
  customerPhone: PropTypes.string,
  locations: PropTypes.array.isRequired,
  currentSpending: PropTypes.number.isRequired,
  monthlyQuotedPrice: PropTypes.number.isRequired,
  tableCellWidths: PropTypes.array.isRequired,
  push: PropTypes.func.isRequired,
  hasIncumbentRebateProgram: PropTypes.bool.isRequired,
};

QuotedOpportunitiesTableRow.defaultProps = {
  partnerName: undefined,
  contactName: undefined,
  customerEmail: undefined,
  customerPhone: undefined,
  exContractExpireDate: undefined,
  followUpDate: undefined,
  partnerUserFirstName: undefined,
  partnerUserLastName: undefined,
};

class QuotedOpportunitiesSection extends PureComponent {
  componentDidMount() {
    const {
      history,
      loadOpportunities,
      location: { pathname },
    } = this.props;
    this.unlistenHistory = history.listen(location => {
      if (pathname === location.pathname) {
        const { page, limit, sortedBy, sortOrder } = getQueryParams(location.search);
        loadOpportunities(QUOTED, page, limit, sortedBy, sortOrder);
      }
    });
  }

  componentWillUnmount() {
    const { resetOpportunities } = this.props;
    this.unlistenHistory();
    resetOpportunities();
  }

  onSortOrderChange = (sortedBy, sortOrder) => {
    const { location, push } = this.props;
    push(createUrl(location.pathname, location.search, { sortedBy, sortOrder }));
  };

  render() {
    const {
      isLoading,
      opportunities,
      total,
      location: { search },
      push,
      isPartnerSuperAdmin,
    } = this.props;
    const { sortOrder, sortedBy } = getQueryParams(search);
    const user = getUser();

    const tableCellWidths = isPartnerSuperAdmin
      ? ['9%', '10%', '22%', '8%', '5%', '7%', '7%', '6%', '8%', '9%', '9%']
      : ['10%', undefined, '20%', '9%', '6%', '9%', '8%', '8%', '10%', '10%', '10%'];

    const quotedOpportunitiesTableCells = [
      { name: 'lastEditDate', label: 'Date', width: tableCellWidths[0], sortable: true },
      { name: 'partnerName', label: 'Partner name', width: tableCellWidths[1], sortable: true },
      { name: 'userName', label: 'User name', width: tableCellWidths[8], sortable: true },
      { name: 'contactDetails', label: 'Contact Details', width: tableCellWidths[2], sortable: true },
      { name: 'businessName', label: 'Business Name', width: tableCellWidths[3] },
      { name: 'noOfLocations', label: 'No of sites', width: tableCellWidths[4] },
      { name: 'noOfServices', label: 'No of services', width: tableCellWidths[5] },
      { name: 'currentSpending', label: 'Current spent', width: tableCellWidths[6], sortable: true },
      { name: 'quoted', label: 'Quoted', width: tableCellWidths[7] },
      { name: 'followUpDate', label: 'Follow Up Date', width: tableCellWidths[9] },
      { name: 'exContractExpireDate', label: 'Renewal Date', width: tableCellWidths[10] },
    ];

    if (user.hasIncumbentRebateProgram) {
      tableCellWidths[6] = undefined;
      tableCellWidths[2] = '28%';
      tableCellWidths[3] = '12%';
      quotedOpportunitiesTableCells[3].width = '28%';
      quotedOpportunitiesTableCells[4].width = '12%';
      if (!isPartnerSuperAdmin) {
        tableCellWidths[2] = '26%';
        tableCellWidths[3] = '13%';
        quotedOpportunitiesTableCells[3].width = '26%';
        quotedOpportunitiesTableCells[4].width = '13%';
      }
      quotedOpportunitiesTableCells.splice(7, 1);
    }

    if (!isPartnerSuperAdmin) {
      quotedOpportunitiesTableCells.splice(1, 1);
    }

    return (
      <Panel padding="no no xxSmall" isLoading={isLoading}>
        {total > 0 && (
          <Table
            cells={quotedOpportunitiesTableCells}
            rows={opportunities}
            rowComponent={QuotedOpportunitiesTableRow}
            rowProps={{ tableCellWidths, push, hasIncumbentRebateProgram: user.hasIncumbentRebateProgram }}
            sort={this.onSortOrderChange}
            sortOrder={sortOrder}
            sortedBy={sortedBy}
            withClickableRows
            smallPadding
          />
        )}
        {total === 0 && <Message padding="sMedium">There are no quoted opportunities</Message>}

        {total > LIMIT_PER_PAGE && (
          <Pagination totalResults={total} limitPerPage={LIMIT_PER_PAGE} maxPaginationItems={10} />
        )}
      </Panel>
    );
  }
}
QuotedOpportunitiesSection.propTypes = {
  history: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  opportunities: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  loadOpportunities: PropTypes.func.isRequired,
  resetOpportunities: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  location: RouterPropTypes.location.isRequired,
  isPartnerSuperAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isLoading: state.opportunities.opportunities.isLoading,
  opportunities: opportunitiesWithMontlyQuotedPricesSelector(state.opportunities.opportunities),
  total: state.opportunities.opportunities.total,
  isPartnerSuperAdmin: isPartnerSuperAdminSelector(state.account.login),
});

const mapDispatchToProps = { loadOpportunities, resetOpportunities, push };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(QuotedOpportunitiesSection),
);
