import { mapKeys, mapValues, sortBy } from 'lodash-es';
import { FRONT_LOAD, CART } from './serviceTypes';

export const FRONT_LOAD_CONTAINERS = mapKeys(
  [
    { name: '1 YD', value: 'FL01YD', size: 1, canHaveCasters: true },
    { name: '1.5 YD', value: 'FL1+YD', size: 1.5, canHaveCasters: true },
    { name: '2 YD', value: 'FL02YD', size: 2, canHaveCasters: true },
    { name: '3 YD', value: 'FL03YD', size: 3, canHaveCasters: true },
    { name: '4 YD', value: 'FL04YD', size: 4, canHaveCasters: true },
    { name: '6 YD', value: 'FL06YD', size: 6, canHaveCasters: false },
    { name: '8 YD', value: 'FL08YD', size: 8, canHaveCasters: false },
    { name: '10 YD', value: 'FL10YD', size: 10, canHaveCasters: false },
  ],
  'size',
);

export const FRONT_LOAD_CONTAINER_SIZES = sortBy(mapValues(FRONT_LOAD_CONTAINERS, 'size'));

export const CART_CONTAINERS = mapKeys(
  [
    { name: '35 gal', value: 'TTR35GT', size: 35 },
    { name: '48 gal', value: 'TTR48GT', size: 48 },
    { name: '64 gal', value: 'TTR64GT', size: 64 },
    { name: '96 gal', value: 'TTR96GT', size: 96 },
  ],
  'size',
);

export const CART_CONTAINER_SIZES = sortBy(mapValues(CART_CONTAINERS, 'size'));

export const CONTAINERS_BY_SERVICE_TYPE = {
  [FRONT_LOAD]: FRONT_LOAD_CONTAINERS,
  [CART]: CART_CONTAINERS,
};
