import React from 'react';
import { Switch } from 'react-router';
import {
  LoginPage,
  LogoutPage,
  RequestPasswordResetPage,
  PasswordRequestSentPage,
  ResetPasswordPage,
  AcceptInvitationPage,
  ForcedPasswordUpdatePage,
} from './pages';
import { GuestRoute, AuthRoute, AccountPage } from '.';

const AccountRouter = () => (
  <Switch>
    <AccountPage>
      <GuestRoute exact path="/account/login" component={LoginPage} />
      <GuestRoute exact path="/account/forgot-password" component={RequestPasswordResetPage} />
      <GuestRoute exact path="/account/forgot-password-email-sent" component={PasswordRequestSentPage} />
      <GuestRoute exact path="/account/reset-password" component={ResetPasswordPage} />
      <GuestRoute exact path="/account/update-password" component={ForcedPasswordUpdatePage} />
      <GuestRoute exact path="/account/accept-invitation" component={AcceptInvitationPage} />
      <AuthRoute exact path="/account/logout" component={LogoutPage} />
    </AccountPage>
  </Switch>
);

export default AccountRouter;
