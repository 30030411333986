import styled from 'styled-components';
import { colorMapper, media } from '../../../utils/styles';

export const OpportunityServiceDetailTitle = styled.h4`
  margin: 20px 10px;
`;

export const OpportunityServiceDetailLabel = styled.span`
  display: block;
  margin-bottom: 5px;
  text-transform: capitalize;
  line-height: 16px;
  font-size: 14px
  color: ${props => props.theme.grayDark};

  ${media.phone`
    line-height: 14px;
    font-size: 12px;
  `}
`;

export const OpportunityServiceDetailValue = styled.span`
  display: block;
  margin-bottom: 10px;
  line-height: 18px;
  font-size: 14px;
  color: ${colorMapper};

  ${media.phone`
    line-height: 16px;
  `}
`;

export const OpportunityServiceDetail = styled.div``;

export const OpportunityServiceDetails = styled.div`
  margin-bottom: 10px;

  &:last-child {
    border-bottom: none;
    margin-bottom: none;
  }
`;

export const OpportunityServiceListTitle = styled.div`
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: ${props => props.theme.fontWeightMedium};

  ${media.phone`
    font-size: 16px;
  `}
`;

export const OpportunityServiceListSubTitle = styled.div`
  font-size: 18px;

  ${media.phone`
    font-size: 14px;
  `}
`;

export const OpportunityServiceListHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const OpportunityServiceList = styled.ul`
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 10px;
  border-bottom: 1px solid ${props => props.theme.grayLight};

  &:last-child {
    border-bottom: none;
    margin-bottom: none;
  }
`;
