import styled, { css } from 'styled-components';

export const MapLegendContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  min-width: 140px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  opacity: ${props => (props.expanded ? 1 : 0.8)};
  z-index: 10;

  &:hover,
  &:focus {
    opacity: 1;
  }
`;

export const MapLegendHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 14px;
`;

export const MapLegendTitle = styled.div``;

export const MapLegendToggle = styled.button`
  width: 14px;
  height: 14px;
  background-color: transparent;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTJweCIgaGVpZ2h0PSI4cHgiIHZpZXdCb3g9IjAgMCAxMiA4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1My4yICg3MjY0MykgLSBodHRwczovL3NrZXRjaGFwcC5jb20gLS0+CiAgICA8dGl0bGU+ZXhwYW5kIGFycm93PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IjAzLVNlcnZpY2VzLeKAlC1XSVAiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSIxMC1TZXJ2aWNlcy0vLUxlZ2VuZC1leHBhbmRlZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEzMDEuMDAwMDAwLCAtNzcyLjAwMDAwMCkiIGZpbGw9IiMwMDAwMDAiPgogICAgICAgICAgICA8ZyBpZD0iTWF0ZXJpYWwvSWNvbnMtYmxhY2svZXhwYW5kLWxlc3MiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEzMDcuMDAwMDAwLCA3NzYuMDAwMDAwKSBzY2FsZSgxLCAtMSkgdHJhbnNsYXRlKC0xMzA3LjAwMDAwMCwgLTc3Ni4wMDAwMDApIHRyYW5zbGF0ZSgxMjk1LjAwMDAwMCwgNzY0LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlNoYXBlIiBwb2ludHM9IjEyIDggNiAxNCA3LjQgMTUuNCAxMiAxMC44IDE2LjYgMTUuNCAxOCAxNCI+PC9wb2x5Z29uPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  border: none;
  outline: none;
  cursor: pointer;

  ${props =>
    !props.expanded &&
    css`
      transform: rotate(180deg);
    `}
`;

export const MapLegendBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const MapLegendItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0px;
  font-size: 12px;
  align-items: center;
`;

export const MapLegendItemIcon = styled.div`
  width: 21px;
  height: 21px;
  margin-right: 5px;
  background: url(${props => props.icon});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`;
