import styled from 'styled-components';
import { mapper } from '../../../utils/styles';
import ballonsImage from '../../assets/img/ballons.png';

const BallonsImage = styled.div`
  width: 100%;
  height: 100%;
  margin: ${props => mapper(props.margin, { no: 0, small: '10px', medium: '20px', large: '30px' }, 'no')};
  background: no-repeat;
  background-image: url(${ballonsImage});
  background-size: cover;
`;

export default BallonsImage;
