import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import { identity, isNumber, orderBy, toLower } from 'lodash-es';
import { ASC, DESC } from '../constants';
import {
  Table as TableContainer,
  TableHead,
  TableRow,
  TableHeadCell,
  TableHeadCellSortIcon,
  TableBody,
} from './styled';

const getOrderedRows = (rows, sortedBy, sortOrder) =>
  orderBy(rows, [row => (isNumber(row[sortedBy]) ? row[sortedBy] : toLower(row[sortedBy]))], [sortOrder]);

const orderedRowsSelector = createSelector(
  getOrderedRows,
  identity,
);

const TableCells = ({ rows, rowComponent: RowComponent, rowProps }) => (
  <>
    {rows.map((row, index) => (
      /* eslint-disable-next-line react/no-array-index-key */
      <RowComponent key={index} index={index} orderNumber={index} {...rowProps} {...row} />
    ))}
  </>
);

TableCells.propTypes = {
  rows: PropTypes.array.isRequired,
  rowComponent: PropTypes.func.isRequired,
  rowProps: PropTypes.object,
};

TableCells.defaultProps = {
  rowProps: undefined,
};

class Table extends PureComponent {
  constructor(props) {
    super(props);
    const { sortedBy, sortOrder } = props;
    this.state = { sortedBy, sortOrder };
  }

  static getDerivedStateFromProps(props, state) {
    const { sortedBy, sortOrder } = props;
    const { sortedBy: prevSortedBy, sortOrder: prevSortOrder } = state;
    if (sortedBy !== prevSortedBy || sortOrder !== prevSortOrder) {
      return { ...state, sortedBy, sortOrder };
    }
    return { ...state };
  }

  onSortableTableHeadCellClick = name => {
    this.setState(prevState => {
      const sortedBy = name;
      const sortOrder = prevState.sortedBy !== name || prevState.sortOrder === DESC ? ASC : DESC;

      const { sort } = this.props;
      if (sort) sort(sortedBy, sortOrder);

      return { sortedBy, sortOrder };
    });
  };

  render() {
    const { cells, rows, rowComponent, rowProps, sort, withClickableRows, smallPadding } = this.props;
    const { sortedBy, sortOrder } = this.state;

    const orderedRows = sort ? rows : orderedRowsSelector(rows, sortedBy, sortOrder);

    return (
      <TableContainer>
        <TableHead>
          <TableRow>
            {cells.map(({ name, label, component: Component, componentProps, sortable, ...cellProps }) => (
              <TableHeadCell
                smallPadding={smallPadding}
                key={name}
                sortable={sortable}
                isSortedBy={sortedBy === name}
                sortOrder={sortOrder}
                onClick={sortable ? () => this.onSortableTableHeadCellClick(name) : undefined}
                {...cellProps}
              >
                {Component ? <Component {...componentProps} /> : label}
                <TableHeadCellSortIcon />
              </TableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody withClickableRows={withClickableRows}>
          <TableCells rows={orderedRows} rowComponent={rowComponent} rowProps={rowProps} />
        </TableBody>
      </TableContainer>
    );
  }
}

Table.propTypes = {
  cells: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  rowComponent: PropTypes.func.isRequired,
  sort: PropTypes.func,
  sortedBy: PropTypes.string,
  sortOrder: PropTypes.string,
  rowProps: PropTypes.object,
  withClickableRows: PropTypes.bool,
  smallPadding: PropTypes.bool,
};

Table.defaultProps = {
  rowProps: undefined,
  sort: undefined,
  sortedBy: undefined,
  sortOrder: undefined,
  withClickableRows: undefined,
  smallPadding: undefined,
};

export default Table;
