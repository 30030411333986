import React from 'react';
import PropTypes from 'prop-types';
import { BallonsImage } from '../../common/components/styled';
import { AccountPage as AccountPageContainer, AccountPagePanel, AccountPageContent, AccountPanelLogo } from './styled';

const AccountPage = ({ children }) => (
  <AccountPageContainer>
    <AccountPagePanel sizePhone="large">
      <AccountPageContent>
        <AccountPanelLogo />
        {children}
      </AccountPageContent>
    </AccountPagePanel>
    <AccountPagePanel sizePhone="small">
      <BallonsImage />
    </AccountPagePanel>
  </AccountPageContainer>
);

AccountPage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AccountPage;
