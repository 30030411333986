import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { PanelSection, ButtonSet, Button, Container } from '../../../core/components/styled';
import { Input } from '../../../core/components';
import { AccountPanelError, AccountPanelLink } from '../styled';
import { isRequired } from '../../../utils/services/validator';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';

const LoginForm = ({ isLoginFailed, handleSubmit, errorMessage }) => (
  <form onSubmit={handleSubmit} noValidate>
    <PanelSection>
      <Field name="email" component={Input} label="Email address" type="text" validate={[isRequired]} />
      <Field name="password" component={Input} label="Password" type="password" validate={[isRequired]} />

      {isLoginFailed && <AccountPanelError>{errorMessage}</AccountPanelError>}

      <ButtonSet margin="medium no">
        <Button type="submit" color="primary">
          Login
        </Button>
      </ButtonSet>

      <Container align="center">
        <AccountPanelLink to="/account/forgot-password">Forgot Password?</AccountPanelLink>
      </Container>
    </PanelSection>
  </form>
);

LoginForm.propTypes = {
  isLoginFailed: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

export default reduxForm({
  form: 'login',
  onSubmitFail: focusFirstInvalidField,
})(LoginForm);
