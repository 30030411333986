import http from '../../core/services/http';
import { createSession, setSessionValues, getSessionValue, destroySession } from './session';
import transformLogin from './transformLogin';

export const getUser = () => getSessionValue('user');
export const getAuthToken = () => getSessionValue('authToken');
export const getRefreshToken = () => getSessionValue('refreshToken');

export const login = async (email, password) => {
  const response = await http.post('partnerportal/login', { email, password });

  const user = transformLogin(response.data);
  const authToken = response.headers.authorization;
  const refreshToken = response.headers.refreshtoken;
  if (user.isPasswordExpired) {
    window.location.href = '/account/update-password';
    setSessionValues({
      authToken,
    });
    return null;
  }
  createSession(user, authToken, refreshToken);
  return user;
};

export const refreshAuthToken = async () => {
  const refreshToken = getRefreshToken();
  const response = await http.post('partnerportal/refreshtoken', { refreshToken });
  const authToken = response.headers.authorization;
  const user = response.data;

  setSessionValues({ user, authToken });
  return user;
};

export const logout = () => {
  destroySession();
};
