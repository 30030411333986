import styled, { css } from 'styled-components';
import { mapper, sizeMapper, colorMapper, media } from '../../../utils/styles';

const Text = styled.span`
  margin: ${props => sizeMapper(props.margin, 'no')};
  padding: ${props => sizeMapper(props.padding, 'no')};
  font-size: ${props => mapper(props.size, { small: '12px', medium: '14px', large: '16px', xLarge: '18px' })};

  ${props =>
    props.sizeTablet &&
    css`
      ${media.tablet`
        font-size:  ${props =>
          mapper(props.sizeTablet, { small: '12px', medium: '14px', large: '16px', xLarge: '18px' })};
      `};
    `};

  ${props =>
    props.sizePhone &&
    css`
      ${media.phone`
        font-size:  ${props =>
          mapper(props.sizePhone, { small: '12px', medium: '14px', large: '16px', xLarge: '18px' })};
      `};
    `};

  ${props =>
    props.block &&
    css`
      display: block;
    `};

  ${props =>
    props.weight &&
    css`
      font-weight: ${props =>
        mapper(props.weight, {
          light: props.theme.fontWeightLight,
          normal: props.theme.fontWeightNormal,
          medium: props.theme.fontWeightMedium,
        })};
    `};

  ${props =>
    props.align &&
    css`
      text-align: ${props => mapper(props.align, { left: 'left', center: 'center', right: 'right' }, 'left')};
    `};

  ${props =>
    props.color &&
    css`
      color: ${colorMapper(props)};
    `};
`;

export default Text;
