import { combineReducers } from 'redux';
import { reducer as opportunitiesReducer } from './opportunities';
import { reducer as opportunityReducer } from './opportunity';

export {
  loadOpportunities,
  resetOpportunities,
  exportOpportunities,
  opportunitiesWithMontlyQuotedPricesSelector,
} from './opportunities';
export { loadOpportunity, resetOpportunity } from './opportunity';

export const reducer = combineReducers({
  opportunities: opportunitiesReducer,
  opportunity: opportunityReducer,
});
