import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Resolver } from '../../../core/components';
import { PageLoading } from '../../../common/components/styled';
import { isPartnerSuperAdminSelector } from '../../../account/ducks';
import { loadUser, loadCompanies } from '../../ducks';
import UserEditorModal from './UserEditorModal';

class UserEditorModalResolver extends PureComponent {
  resolve = () => {
    const { loadUser, loadCompanies, isPartnerSuperAdmin, userId } = this.props;
    if (isPartnerSuperAdmin) {
      return userId ? Promise.all([loadUser(userId), loadCompanies()]) : loadCompanies();
    }
    return userId ? loadUser(userId) : Promise.resolve();
  };

  render() {
    const { closeModal, saveUser, userId } = this.props;
    return (
      <Resolver
        successComponent={UserEditorModal}
        successProps={{ userId, saveUser, closeModal }}
        loadingComponent={PageLoading}
        resolve={this.resolve}
      />
    );
  }
}

UserEditorModalResolver.propTypes = {
  userId: PropTypes.string,
  isPartnerSuperAdmin: PropTypes.bool.isRequired,
  loadUser: PropTypes.func.isRequired,
  loadCompanies: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  saveUser: PropTypes.func.isRequired,
};

UserEditorModalResolver.defaultProps = {
  userId: undefined,
};

const mapStateToProps = state => ({
  isPartnerSuperAdmin: isPartnerSuperAdminSelector(state.account.login),
});

const mapDispatchToProps = { loadUser, loadCompanies };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserEditorModalResolver);
