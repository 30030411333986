import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NonMobile, Mobile } from '../Responsive';
import DesktopNavBar from './DesktopNavBar';
import MobileNavBar from './MobileNavBar';
import { AccountProfileModalResolver } from '../../../account/components/modals';

class ResponsiveNavBar extends Component {
  state = {
    isAccountProfileModalOpen: false,
  };

  openUserProfileModal = () => {
    this.setState({ isAccountProfileModalOpen: true });
  };

  closeUserProfileModal = () => {
    this.setState({ isAccountProfileModalOpen: false });
  };

  render() {
    const { username, isPartnerSuperAdmin } = this.props;
    const { isAccountProfileModalOpen } = this.state;

    return (
      <>
        <NonMobile>
          <DesktopNavBar
            username={username}
            isPartnerSuperAdmin={isPartnerSuperAdmin}
            openUserProfileModal={this.openUserProfileModal}
          />
        </NonMobile>
        <Mobile>
          <MobileNavBar
            username={username}
            isPartnerSuperAdmin={isPartnerSuperAdmin}
            openUserProfileModal={this.openUserProfileModal}
          />
        </Mobile>
        {isAccountProfileModalOpen && <AccountProfileModalResolver closeModal={this.closeUserProfileModal} />}
      </>
    );
  }
}

ResponsiveNavBar.propTypes = {
  username: PropTypes.string.isRequired,
  isPartnerSuperAdmin: PropTypes.bool.isRequired,
};

export default ResponsiveNavBar;
