import http from '../../core/services/http';
import { transformLoadQuote, transformSaveQuote } from './transformQuote';

export const createQuote = quoteData =>
  http.post('/partner/quote', transformSaveQuote(quoteData)).then(response => transformLoadQuote(response.data));

export const updateQuote = quoteData =>
  http.put('/partner/quote', transformSaveQuote(quoteData)).then(response => transformLoadQuote(response.data));

export const loadQuote = quoteId =>
  http.get(`/partner/quote/${quoteId}`).then(response => transformLoadQuote(response.data));

export const sendShoppingCart = quoteId => http.post(`/partner/cart/send/${quoteId}`).then(response => response.data);

export const signWithCustomer = quoteId =>
  http.post(`/partner/cart/signwithcustomer/${quoteId}`).then(response => response.data.url);
