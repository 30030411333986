import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isPartnerSuperAdminSelector } from '../../account/ducks';
import { Page as PageContainer, PageContent } from './styled';
import { NavigationBar } from '.';

class Page extends PureComponent {
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { firstName, isPartnerSuperAdmin, children } = this.props;

    return (
      <PageContainer>
        <NavigationBar username={firstName} isPartnerSuperAdmin={isPartnerSuperAdmin} />
        <PageContent>{children}</PageContent>
      </PageContainer>
    );
  }
}

Page.propTypes = {
  firstName: PropTypes.string,
  children: PropTypes.node.isRequired,
  location: RouterPropTypes.location.isRequired,
  isPartnerSuperAdmin: PropTypes.bool.isRequired,
};

Page.defaultProps = {
  firstName: '',
};

const mapStateToProps = state => ({
  firstName: state.account.login.user.firstName,
  isPartnerSuperAdmin: isPartnerSuperAdminSelector(state.account.login),
});

export default withRouter(connect(mapStateToProps)(Page));
