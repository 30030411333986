import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { Link } from 'react-router-dom';
import { Icon } from '../../../core/components';
import { loading, loadingOverlay } from '../../../core/styles';
import { alignMapper, media } from '../../../utils/styles';

export const PageTitle = styled.h1`
  text-transform: capitalize;
  line-height: 40px;
  font-weight: ${props => props.theme.fontWeightLight};
  font-size: 32px;

  ${media.tablet`
    font-size: 26px;
  `};

  ${media.phone`
    font-size: 18px;
    font-weight: ${props => props.theme.fontWeightMedium};
  `};
`;

export const PageBackButtonIcon = styled(Icon).attrs({ icon: 'back' })`
  width: 16px;
  height: 16px;
  margin-right: 8px;

  ${media.phone`
    width: 14px;
    height: 14px;
  `};
`;

export const PageBackButton = styled(Link)`
  height: 40px;
  width: 20px;
  color: ${props => props.theme.brandPrimary};
`;

export const PageActions = styled.div`
  display: flex;
  min-width: fit-content;
  flex: 1;
  justify-content: flex-end;

  ${props =>
    props.alignPhone &&
    css`
      ${media.phone`
      justify-content: ${props => alignMapper(props)};
      `}
    `}
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 30px 0;

  ${media.custom(1070)`
    padding: 30px 25px;
    background-color: #fff;
    box-shadow: 0 4px 24px ${transparentize(0.85, '#000')};
  `}

  ${media.tablet`
    padding: 15px 20px;
  `}

  ${media.phone`
     padding: 10px 14px;
  `}
`;

export const PageContent = styled.div`
  box-sizing: border-box;
  position: relative;
  max-width: 1180px;
  margin: 0 auto 70px;

  ${media.phone`
    margin: 0px auto;
  `}
`;

export const PageLoading = styled.div`
  height: calc(100vh - 70px);

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -16px 0 0 -16px;
    ${loading('24px')};
  }
`;

export const PageLoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  ${props => loadingOverlay('24px', props.theme.grayBase, 0.7, 4000)};
`;

export const Page = styled.div``;
