import { mapKeys } from 'lodash-es';
import { FRONT_LOAD, CART } from './serviceTypes';

export const RECURRING_FREQUENCIES = mapKeys(
  [
    { name: 'Every Other Month', value: 0.1154 },
    { name: '1x Monthly', value: 0.2309 },
    { name: 'Every Other Week', value: 0.5 },
    { name: '1x Weekly', value: 1 },
    { name: '2x Weekly', value: 2 },
    { name: '3x Weekly', value: 3 },
    { name: '4x Weekly', value: 4 },
    { name: '5x Weekly', value: 5 },
    { name: '6x Weekly', value: 6 },
    { name: '7x Weekly', value: 7 },
  ],
  'value',
);

export const RECURRING_FREQUENCIES_BY_SERVICE_TYPE = {
  [FRONT_LOAD]: [0.5, 1, 2, 3, 4, 5, 6, 7],
  [CART]: [0.5, 1, 2, 3, 4, 5, 6, 7],
};
