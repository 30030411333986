import { useEffect, useState } from 'react';

import memoizeOne from 'memoize-one';
import { spring } from 'react-motion';

const useClusterMarker = ({
  initialScale = 0.6,
  defaultScale = 1,
  hoveredScale = 1.15,
  hovered,
  stiffness,
  damping,
  precision,
}) => {
  const [defaultMotionStyle, setDefaultMotionStyle] = useState({ scale: initialScale });
  const [motionStyle, setMotionStyle] = useState();

  useEffect(() => {
    setDefaultMotionStyle({ scale: defaultScale });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMotionStyle({
      scale: spring(hovered ? hoveredScale : defaultScale, {
        stiffness,
        damping,
        precision,
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hovered]);

  return [defaultMotionStyle, motionStyle];
};

const useClusterMarkerMemoized = memoizeOne(useClusterMarker);

export default useClusterMarkerMemoized;
