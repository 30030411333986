import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { fieldInputPropTypes } from 'redux-form';
import DatePickerInput from 'react-day-picker/DayPickerInput';
import { FormGroup, FormError, DatePicker as DatePickerContainer } from './styled';

const DatePicker = ({ input, label, disabledDays, readOnly, meta: { submitFailed, error } }) => {
  const dateFormat = 'MM/DD/YYYY';

  const onDayChange = date => {
    input.onChange(moment(date).format(dateFormat) || null);
  };

  const formatDate = (str, format) => moment(str).format(format);
  return (
    <FormGroup>
      <DatePickerContainer>
        <DatePickerInput
          format={dateFormat}
          formatDate={formatDate}
          inputProps={{ readOnly }}
          onDayChange={onDayChange}
          value={input.value}
          placeholder={label}
          dayPickerProps={{
            disabledDays,
          }}
        />
      </DatePickerContainer>

      {submitFailed && error && <FormError>{error}</FormError>}
    </FormGroup>
  );
};

DatePicker.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  label: PropTypes.string.isRequired,
  disabledDays: PropTypes.any,
  readOnly: PropTypes.any,
  meta: PropTypes.any,
};

DatePicker.defaultProps = {
  disabledDays: [],
  readOnly: false,
  meta: undefined,
};

export default DatePicker;
