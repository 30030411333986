import { combineReducers } from 'redux';
import { reducer as quoteReducer } from './quote';

export {
  recalculateStatus,
  updateCustomer,
  addLocation,
  updateLocation,
  deleteLocation,
  addService,
  updateService,
  updateServicePrice,
  deleteService,
  saveQuote,
  loadQuote,
  sendShoppingCart,
  signWithCustomer,
  resetQuote,
  locationsSelector,
  locationByServiceIdSelector,
  locationsWithServicesSelector,
  locationCountSelector,
  serviceByIdSelector,
  serviceCountSelector,
  qualifiedServiceCountSelector,
  billableLocationsCountSelector,
} from './quote';

export const reducer = combineReducers({
  quote: quoteReducer,
});
