import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isPartnerSuperAdminSelector } from '../ducks';

const SuperAdminGuard = ({ isPartnerSuperAdmin, children }) => (isPartnerSuperAdmin ? children : null);

SuperAdminGuard.propTypes = {
  isPartnerSuperAdmin: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = state => ({
  isPartnerSuperAdmin: isPartnerSuperAdminSelector(state.account.login),
});

export default connect(mapStateToProps)(SuperAdminGuard);
