import { combineReducers } from 'redux';
import { reducer as notificationsReducer } from './notifications';
import { reducer as lastLocationsReducer } from './lastLocations';

export { createNotification, removeNotification } from './notifications';
export { addLastLocation, getLastUrlSelector } from './lastLocations';

export const reducer = combineReducers({
  notifications: notificationsReducer,
  lastLocations: lastLocationsReducer,
});
