import styled, { css } from 'styled-components'
import { transparentize } from 'polished'
import { BareButtonStyle } from './Button'
import { InputStyle } from './Input'
import { SelectStyle } from './Select'

const DatePicker = styled.div`
  ${props => props.cieTradeWide
    && css`
      width: 360px;
    `}

  .DayPicker {
    display: inline-block;
  }

  .DayPicker-wrapper {
    position: relative;
    flex-direction: row;
    user-select: none;
  }

  .DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .DayPicker-Month {
    display: table;
    margin: 15px 15px 0;
    border-collapse: collapse;
    border-spacing: 0;
    user-select: none;
  }

  .DayPicker-NavBar {
    position: relative;
  }

  .DayPicker-NavButton {
    position: absolute;
    top: 10px;
    display: inline-block;
    padding: 12px 14px;
    cursor: pointer;
    background-size: 50%;
    background: center no-repeat;
    border-radius: 4px;
    transition: opacity 0.2s ease-out;

    &:active {
      background-color: #f2f2f3;
    }
  }

  .DayPicker-NavButton--prev {
    left: 15px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNy40IDEuNEw2IDAgMCA2bDYgNiAxLjQtMS40TDIuOCA2eiIgZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJldmVub2RkIiBmaWxsLW9wYWNpdHk9IjAuNSIvPjwvc3ZnPg==');
  }

  .DayPicker-NavButton--next {
    right: 15px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNLjYgMS40TDIgMGw2IDYtNiA2LTEuNC0xLjRMNS4yIDZ6IiBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtb3BhY2l0eT0iMC41Ii8+PC9zdmc+);
  }

  .DayPicker-NavButton--interactionDisabled {
    display: none;
  }

  .DayPicker-Caption {
    display: table-caption;
    margin-bottom: 15px;
    text-align: center;
    font-weight: ${props => props.theme.fontWeightBold};
    font-size: 14px;
  }

  .DayPicker-Weekdays {
    display: table-header-group;
    margin-top: 15px;
  }

  .DayPicker-WeekdaysRow {
    display: table-row;
  }

  .DayPicker-Weekday {
    display: table-cell;
    padding: 8px;
    text-align: center;
    font-weight: ${props => props.theme.fontWeightBold};
    font-size: 12px;
    color: ${transparentize(0.6, '#000')};
  }

  .DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
  }

  .DayPicker-Body {
    display: table-row-group;
  }

  .DayPicker-Week {
    display: table-row;
  }

  .DayPicker-Day {
    display: table-cell;
    padding: 8px;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
  }

  .DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
  }

  .DayPicker-Footer {
    margin-top: 15px;
    padding: 4px 0;
    text-align: center;
  }

  .DayPicker-TodayButton {
    ${BareButtonStyle};
    padding: 8px 8px;
    border-radius: 4px;
    font-weight: ${props => props.theme.fontWeightBold};
    font-size: 11px;
    color: #000;
    transition: opacity 0.2s ease-out;

    &:hover {
      opacity: 1;
    }

    &:active {
      background-color: #f2f2f3;
    }
  }

  .DayPicker-Day--today {
    font-weight: ${props => props.theme.fontWeightBold};
    color: ${props => props.theme.brandPrimary};
  }

  .DayPicker-Day--disabled {
    cursor: default;
    color: ${transparentize(0.6, '#000')};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled) {
    background-color: ${props => props.theme.brandPrimary};
    border-radius: 100%;
    color: #fff;
  }

  .DayPicker:not(.DayPicker--interactionDisabled) {
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):hover {
      background-color: ${props => transparentize(0.9, props.theme.brandPrimary)};
      border-radius: 100%;
    }
  }

  .DayPickerInput {
    * {
      outline: none;
    }

    input {
      ${InputStyle};
      ${SelectStyle};
    }
  }

  .DayPickerInput-OverlayWrapper {
    position: relative;
  }

  .DayPickerInput-Overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2000;
    padding: 10px;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  }
`

export default DatePicker
