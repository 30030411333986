import http from '../../core/services/http';
import { transformLoadOpportunities, transformLoadOpportunity } from './transformOpportunity';

const FIRST_PAGE = 1;
const LIMIT_PER_PAGE = 20;

export const loadOpportunities = (status, page = FIRST_PAGE, limit = LIMIT_PER_PAGE, sortedBy, sortOrder) =>
  http
    .get('/partner/quotes', {
      params: {
        status,
        page,
        limit,
        sortOrder: sortedBy,
        sortDirection: sortOrder,
      },
    })
    .then(response => transformLoadOpportunities(response.data));

export const loadOpportunity = (quoteId, status) =>
  http.get(`/partner/quote/${quoteId}?status=${status}`).then(response => transformLoadOpportunity(response.data));
