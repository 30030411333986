import update from 'immutability-helper';
import {
  loadAccountProfile as doLoadAccountProfile,
  saveAccountProfile as doSaveAccountProfile,
  sendPhoneVerificationCode,
  validatePhoneNumber,
} from '../services/account';

// Actions
const START_SAVE = 'account/accountProfile/START_SAVE';
const COMPLETE_SAVE = 'account/accountProfile/COMPLETE_SAVE';
const FAIL_SAVE = 'account/accountProfile/FAIL_SAVE';
const START_LOAD = 'account/accountProfile/START_LOAD';
const COMPLETE_LOAD = 'account/accountProfile/COMPLETE_LOAD';
const FAIL_LOAD = 'account/accountProfile/FAIL_LOAD';
const START_SEND_CODE = 'account/accountProfile/START_SEND_CODE';
const COMPLETE_SEND_CODE = 'account/accountProfile/COMPLETE_SEND_CODE';
const FAIL_SEND_CODE = 'account/accountProfile/FAIL_SEND_CODE';
const START_VALIDATE_PHONE_NR = 'account/accountProfile/START_VALIDATE_PHONE_NR';
const COMPLETE_VALIDATE_PHONE_NR = 'account/accountProfile/COMPLETE_VALIDATE_PHONE_NR';
const FAIL_VALIDATE_PHONE_NR = 'account/accountProfile/FAIL_VALIDATE_PHONE_NR';
const RESET = 'account/accountProfile/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  isSendingCode: false,
  isValidatingPhoneNr: false,
  accountProfile: undefined,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
          isSaveFailed: true,
        },
      });

    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          accountProfile: action.accountProfile,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          isLoadFailed: true,
        },
      });
    
      case START_SEND_CODE:
        return update(state, {
          $merge: {
            isSendingCode: true,
          },
        });
  
      case COMPLETE_SEND_CODE:
        return update(state, {
          $merge: {
            isSendingCode: false,
          },
        });
  
      case FAIL_SEND_CODE:
        return update(state, {
          $merge: {
            isSendingCode: false,
            isSendingCodeFailed: true,
          },
        });

        case START_VALIDATE_PHONE_NR:
          return update(state, {
            $merge: {
              isValidatingPhoneNr: true,
            },
          });
    
        case COMPLETE_VALIDATE_PHONE_NR:
          return update(state, {
            $merge: {
              isValidatingPhoneNr: false,
            },
          });
    
        case FAIL_VALIDATE_PHONE_NR:
          return update(state, {
            $merge: {
              isValidatingPhoneNr: false,
              isValidatingPhoneNrFailed: true,
            },
          });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = accountProfile => ({
  type: COMPLETE_LOAD,
  accountProfile,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSendCode = () => ({
  type: START_SEND_CODE,
});

const completeSendCode = () => ({
  type: COMPLETE_SEND_CODE,
});

const failSendCode = () => ({
  type: FAIL_SEND_CODE,
});

const startValidatePhoneNr = () => ({
  type: START_VALIDATE_PHONE_NR
});

const completeValidatePhoneNr = () => ({
  type: COMPLETE_VALIDATE_PHONE_NR
});

const failValidatePhoneNr = () => ({
  type: FAIL_VALIDATE_PHONE_NR
});

export const saveAccountProfile = accountProfile => dispatch => {
  dispatch(startSave());
  const saveAccountProfilePromise = doSaveAccountProfile(accountProfile);
  saveAccountProfilePromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

  return saveAccountProfilePromise;
};

export const loadAccountProfile = () => dispatch => {
  dispatch(startLoad());
  const loadAccountProfilePromise = doLoadAccountProfile();
  loadAccountProfilePromise
    .then(accountProfile => dispatch(completeLoad(accountProfile)))
    .catch(() => dispatch(failLoad()));

  return loadAccountProfilePromise;
};

export const sendVerificationCode = params => dispatch => {
  dispatch(startSendCode());
  const sendPhoneVerificationCodePromise = sendPhoneVerificationCode(params);
  sendPhoneVerificationCodePromise
    .then(() => dispatch(completeSendCode()))
    .catch(() => dispatch(failSendCode()));

  return sendPhoneVerificationCodePromise;
};

export const validatePhoneNr = params => dispatch => {
  dispatch(startValidatePhoneNr());
  const validatePhoneNumberPromise = validatePhoneNumber(params);
  validatePhoneNumberPromise
    .then(() => dispatch(completeValidatePhoneNr()))
    .catch(() => dispatch(failValidatePhoneNr()));

  return validatePhoneNumberPromise;
};

export const resetAccountProfile = () => ({
  type: RESET,
});
