import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AUTH_TOKEN_EXPIRATION_TIME } from '../constants';
import { refreshAuthToken } from '../ducks';

class AuthManager extends PureComponent {
  componentDidMount() {
    const { isLoggedIn } = this.props;
    if (isLoggedIn) {
      this.createRefreshAuthTokenTimer();
    }
  }

  componentWillUnmount() {
    this.clearRefreshAuthTokenTimer();
  }

  createRefreshAuthTokenTimer = () => {
    const REFRESH_AUTH_TOKEN_TIMER_ADVANCE = 5;
    const refreshAuthTokenTimerInterval = (AUTH_TOKEN_EXPIRATION_TIME - REFRESH_AUTH_TOKEN_TIMER_ADVANCE) * 60 * 1000;
    this.refreshAuthTokenTimer = setInterval(this.refreshAuthToken, refreshAuthTokenTimerInterval);
  };

  clearRefreshAuthTokenTimer = () => {
    if (this.refreshAuthTokenTimer) {
      clearInterval(this.refreshAuthTokenTimer);
    }
  };

  refreshAuthToken = async () => {
    const { refreshAuthToken } = this.props;
    await refreshAuthToken();
  };

  render() {
    const { children } = this.props;
    return children;
  }
}

AuthManager.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  refreshAuthToken: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isLoggedIn: state.account.login.isLoggedIn,
});

const mapDispatchToProps = { refreshAuthToken };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthManager);
