import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';
import { ConnectedRouter } from 'react-router-redux';
import { ThemeProvider } from 'styled-components';
import { Normalize } from 'styled-normalize';
import { GlobalStyle } from './core/components/styled';
import { theme } from './core/styles';
import { NotificationList, LastLocationsProvider } from './core/components';
import { Page } from './common/components';
import { AutoLogin, AuthManager, AccountRouter, AuthRoute } from './account/components';
import { QuoteRouter } from './quote/components';
import { UsersRouter } from './users/components';
import MarketRouter from './market/components/MarketRouter';
import { OpportunitiesRouter } from './opportunities/components';
import initialize from './core/services/initialize';
import registerAuthInterceptor from './account/services/registerAuthInterceptor';
import history from './core/services/history';
import store from './store';

initialize();
registerAuthInterceptor();

const AppRouter = () => (
  <AuthManager>
    <Page>
      <Route path="/opportunities" component={OpportunitiesRouter} />
      <Route path="/quote" component={QuoteRouter} />
      <Route path="/users" component={UsersRouter} />
      <Route path="/market/market-availability" component={MarketRouter} />
    </Page>
  </AuthManager>
);

render(
  <ThemeProvider theme={theme}>
    <>
      <Normalize />
      <GlobalStyle />
      <Provider store={store}>
        <AutoLogin>
          <ConnectedRouter history={history}>
            <LastLocationsProvider>
              <Switch>
                <Route path="/account" component={AccountRouter} />
                <Redirect exact from="/" to="/opportunities" />
                <AuthRoute component={AppRouter} />
              </Switch>
            </LastLocationsProvider>
          </ConnectedRouter>
          <NotificationList />
        </AutoLogin>
      </Provider>
    </>
  </ThemeProvider>,
  document.getElementById('root'),
);
