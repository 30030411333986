import { get, reduce, replace } from 'lodash-es';
import { EN, DEFAULT_LOCALE, LOCALES } from '../constants';
import * as translationsEn from '../assets/locale/locale-en.json';

const getLocale = () => {
  const locale = navigator.language.substr(0, 2);
  return LOCALES.indexOf(locale) > -1 ? locale : DEFAULT_LOCALE;
};

const locale = getLocale();

const translations = {
  [EN]: translationsEn,
};

const getTranslation = translationKey => get(translations[locale], translationKey, translationKey);

const interpolate = (translation, map) =>
  reduce(
    map,
    (interpolatedTranslation, value, key) => replace(interpolatedTranslation, `{{${key}}}`, value),
    translation,
  );

const translate = (translationKey, map) =>
  map ? interpolate(getTranslation(translationKey), map) : getTranslation(translationKey);

export default translate;
