import { combineReducers } from 'redux';
import { reducer as loginReducer } from './login';
import { reducer as requestPasswordResetReducer } from './requestPasswordReset';
import { reducer as resetPasswordReducer } from './resetPassword';
import { reducer as acceptInvitationReducer } from './acceptInvitation';
import { reducer as accountProfileReducer } from './accountProfile';

export {
  login,
  logout,
  refreshAuthToken,
  resetLogin,
  hasRoleSelector,
  isPartnerSuperAdminSelector,
  isPartnerAdminSelector,
} from './login';
export { requestPasswordReset, resetRequestPasswordReset } from './requestPasswordReset';
export { resetPassword, resetResetPassword, changePassword } from './resetPassword';
export { acceptInvitation, resetAcceptInvitation } from './acceptInvitation';
export {
  saveAccountProfile,
  loadAccountProfile,
  resetAccountProfile,
  sendVerificationCode,
  validatePhoneNr,
} from './accountProfile';

export const reducer = combineReducers({
  login: loginReducer,
  requestPasswordReset: requestPasswordResetReducer,
  resetPassword: resetPasswordReducer,
  acceptInvitation: acceptInvitationReducer,
  profile: accountProfileReducer,
});
