import React from 'react';
import ReactDOM from 'react-dom';
import Confirmation from '../components/Confirmation';
import history from './history';

const confirm = (title, message) => {
  const wrapper = document.body.appendChild(document.createElement('div'));
  let unregisterHistoryChangeEventHandler;

  function dispose() {
    unregisterHistoryChangeEventHandler();

    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(wrapper);
      setTimeout(() => document.body.removeChild(wrapper));
    });
  }

  unregisterHistoryChangeEventHandler = history.listen(dispose);

  const promise = new Promise((resolve, reject) => {
    try {
      ReactDOM.render(<Confirmation title={title} message={message} resolve={resolve} reject={reject} />, wrapper);
    } catch (e) {
      throw e;
    }
  });

  return promise.then(
    () => {
      dispose();
      return true;
    },
    () => {
      dispose();
      return false;
    },
  );
};

export default confirm;
