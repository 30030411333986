import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  isRequired,
  hasCoordinates,
  hasCountry,
  hasState,
  hasZip,
  hasCity,
  hasStreet,
  hasStreetNumber,
} from '../../../utils/services/validator';
import { Input } from '../../../core/components';
import { LocationPicker, BusinessTypeSelect } from '../../../common/components';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';

const LocationEditorForm = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit} noValidate>
    <Field name="businessName" component={Input} label="Business name" validate={isRequired} />

    <Field
      name="address"
      component={LocationPicker}
      label="Address"
      instantValidation
      validate={[hasCoordinates, hasCountry, hasState, hasZip, hasCity, hasStreet, hasStreetNumber]}
    />

    <Field name="businessTypeId" component={BusinessTypeSelect} validate={isRequired} />
  </form>
);

LocationEditorForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'locationEditor',
  onSubmitFail: focusFirstInvalidField,
})(LocationEditorForm);
