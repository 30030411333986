import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { reduce, find, has } from 'lodash-es';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import { transparentize } from 'polished';
import { theme } from '../styles';
import { FormGroup, FormLabel, FormError } from './styled';
import translate from '../services/translate';

const getControlBackgroundImage = state => {
  if (state.isFocused) {
    return 'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBzdHJva2U9IiMwMzk4OEEiIHN0cm9rZS13aWR0aD0iMS42IiBkPSJNMTIgMUw2LjMzMyA2IDEgMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+")';
  }

  if (state.isDisabled) {
    return 'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBzdHJva2U9IiM5Njk2OTYiIHN0cm9rZS13aWR0aD0iMS42IiBkPSJNMTIgMUw2LjMzMyA2IDEgMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+")';
  }

  return 'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBzdHJva2U9IiMwMzk4OEEiIHN0cm9rZS13aWR0aD0iMS42IiBkPSJNMTIgMUw2LjMzMyA2IDEgMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2Utb3BhY2l0eT0iLjcwNCIvPjwvc3ZnPg==")';
};

const typeAheadStyles = {
  container: () => ({}),
  control: (baseStyle, state) => ({
    width: '100%',
    paddingRight: '26px',
    position: 'relative',
    background: 'right 2px center no-repeat',
    backgroundImage: getControlBackgroundImage(state),
    outline: 'none',
    borderStyle: 'solid',
    borderWidth: '0 0 1px 0',
    borderColor: state.isFocused ? theme.brandPrimary : theme.grayLight,
    lineHeight: '22px',
    fontSize: '14px',
    fontWeight: theme.fontWeightNormal,
  }),
  valueContainer: baseStyle => ({ ...baseStyle, padding: '0px 6px 2px 0' }),
  singleValue: baseStyle => ({ ...baseStyle, top: '3px', marginLeft: '0px', transform: 'none' }),
  placeholder: baseStyle => ({
    ...baseStyle,
    whiteSpace: 'nowrap',
    top: '3px',
    marginLeft: '0px',
    transform: 'none',
    textTransform: 'capitalize',
    color: `${transparentize(0.6, '#000')}`,
  }),
  indicatorsContainer: () => ({ position: 'absolute', top: '4px', right: '22px' }),
  dropdownIndicator: () => ({ display: 'none' }),
  clearIndicator: (baseStyle, state) => ({
    '::after': {
      display: 'inline-block',
      width: '10px',
      height: '10px',
      content: '""',
      backgroundColor: state.isDisabled ? theme.grayDark : theme.brandPrimary,
      backgroundImage:
        'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik01LjkxOC45TDUuMS4wODJhLjI2NC4yNjQgMCAwIDAtLjM4MiAwTDMgMS44IDEuMjgyLjA4MmEuMjY0LjI2NCAwIDAgMC0uMzgyIDBMLjA4Mi45YS4yNjQuMjY0IDAgMCAwIDAgLjM4MkwxLjggMyAuMDgyIDQuNzE4YS4yNjQuMjY0IDAgMCAwIDAgLjM4MmwuODE4LjgxOGMuMTEuMTEuMjczLjExLjM4MiAwTDMgNC4ybDEuNzE4IDEuNzE4QS4yOTUuMjk1IDAgMCAwIDQuOTEgNmEuMjk1LjI5NSAwIDAgMCAuMTkxLS4wODJsLjgxOC0uODE4YS4yNjQuMjY0IDAgMCAwIDAtLjM4Mkw0LjIgM2wxLjcxOC0xLjcxOGEuMjY0LjI2NCAwIDAgMCAwLS4zODJ6IiBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=)',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '5px',
      cursor: 'pointer',
    },
    '> svg': { display: 'none' },
  }),
  noOptionsMessage: baseStyles => ({
    ...baseStyles,
    textTransform: 'capitalize',
    lineHeight: '18px',
    fontSize: '14px',
  }),
  menu: baseStyle => ({
    ...baseStyle,
    left: 'initial',
    top: 'initial',
    zIndex: 3000,
    width: 'auto',
    minWidth: '92%',
    marginTop: 0,
    borderRadius: '2px',
    boxShadow: `0 4px 8px ${transparentize(0.9, theme.grayBase)}`,
  }),
  option: (baseStyle, state) => ({
    ...baseStyle,
    ':active': '#f2f2f3',
    paddingRight: '30px',
    backgroundColor: (state.isFocused && '#f3f4f3') || 'transparent',
    color: '#000',
    fontSize: '14px',
  }),
};

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const groupBadgeStyles = {
  display: 'inline-block',
  padding: '3px 6px',
  backgroundColor: '#ebecf0',
  borderRadius: '100%',
  textAlign: 'center',
  lineHeight: '1',
  fontWeight: theme.fontWeightMedium,
  fontSize: '10px',
  color: '#172b4d',
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

class TypeAhead extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { inputText: '', option: undefined };
  }

  onInputChange = inputText => {
    this.setState({ inputText });
  };

  onChange = option => {
    const {
      input: { onChange },
    } = this.props;

    const value = has(option, 'value') ? option.value : null;
    this.setState({ option });
    onChange(value);
  };

  findSelectedOption = value => {
    const { inputValue, defaultOptions } = this.props;

    if (inputValue === null) {
      return '';
    }

    if (inputValue !== '' && value === '') {
      return { label: inputValue };
    }

    const { option } = this.state;

    reduce(
      (option ? [option] : []).concat(defaultOptions || []),
      (selectedOption, option) => {
        if (selectedOption) return selectedOption;
        if (option.value && option.value === value) {
          const formattedOption = option;
          if (formattedOption.label.props) {
            formattedOption.label = option.label.props.address;
          }
          return formattedOption;
        }
        return find(option.options, { value }) || '';
      },
      '',
    );
  };

  noOptionsMessage = ({ inputValue }) => {
    const { noLengthRestriction } = this.props;
    return inputValue.length < 3 && !noLengthRestriction
      ? translate('pleaseTypeAtLeastThreeCharacters')
      : translate('noResults');
  };

  render() {
    const {
      input: { value },
      meta: { submitFailed, error },
      disabled,
      placeholder,
      label,
      getOptions,
      margin,
      inputValue,
      ...props
    } = this.props;

    return (
      <FormGroup margin={margin} hasValue={value || this.state.inputText}>
        {!!label && <FormLabel>{label}</FormLabel>}

        <AsyncSelect
          {...props}
          isAsync
          isDisabled={disabled}
          styles={typeAheadStyles}
          formatGroupLabel={formatGroupLabel}
          loadOptions={getOptions}
          noOptionsMessage={this.noOptionsMessage}
          placeholder={placeholder || ''}
          value={this.findSelectedOption(value)}
          onChange={this.onChange}
          onInputChange={this.onInputChange}
        />

        {submitFailed && error && <FormError>{error}</FormError>}
      </FormGroup>
    );
  }
}

TypeAhead.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  getOptions: PropTypes.func.isRequired,
  defaultOptions: PropTypes.array,
  margin: PropTypes.string,
  inputValue: PropTypes.string,
  noLengthRestriction: PropTypes.bool,
};

TypeAhead.defaultProps = {
  placeholder: undefined,
  label: undefined,
  disabled: false,
  defaultOptions: [],
  margin: undefined,
  inputValue: undefined,
  noLengthRestriction: false,
};

export default TypeAhead;
