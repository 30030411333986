import update from 'immutability-helper';
import { findIndex } from 'lodash-es';
import { loadUsers as doLoadUsers, deleteUser as doDeleteUser } from '../services/user';

// Actions
const START_LOAD = 'users/users/START_LOAD';
const COMPLETE_LOAD = 'users/users/COMPLETE_LOAD';
const FAIL_LOAD = 'users/users/FAIL_LOAD';
const START_DELETE = 'users/users/START_DELETE';
const COMPLETE_DELETE = 'users/users/COMPLETE_DELETE';
const FAIL_DELETE = 'users/users/FAIL_DELETE';
const RESET = 'users/users/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isDeleting: false,
  users: undefined,
  total: undefined,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          users: action.users,
          total: action.total,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE: {
      const userIndex = findIndex(state.users, { userId: action.userId });
      return update(state, {
        users: { $splice: [[userIndex, 1]] },
        $merge: { isDeleting: false },
      });
    }

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (users, total) => ({
  type: COMPLETE_LOAD,
  users,
  total,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = userId => ({
  type: COMPLETE_DELETE,
  userId,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

export const loadUsers = (page, limit, sortedBy, sortOrder, searchTerm) => dispatch => {
  dispatch(startLoad());
  const loadUsersPromise = doLoadUsers(page, limit, sortOrder, sortedBy, searchTerm);
  loadUsersPromise.then(({ users, total }) => dispatch(completeLoad(users, total))).catch(() => dispatch(failLoad()));

  return loadUsersPromise;
};

export const deleteUser = userId => dispatch => {
  dispatch(startDelete());
  const deleteUserPromise = doDeleteUser(userId);
  deleteUserPromise.then(() => dispatch(completeDelete(userId))).catch(() => dispatch(failDelete()));
  return deleteUserPromise;
};

export const resetUsers = () => ({
  type: RESET,
});
