import styled, { css } from 'styled-components';
import { FRONT_LOAD, CART } from '../../constants';
import { mapper } from '../../../utils/styles';
import frontLoadContainerImg from '../../assets/img/frontLoadContainer.png';
import frontLoadContainerWithCastersImg from '../../assets/img/frontLoadContainerWithCasters.png';
import cartContainerImg from '../../assets/img/cartContainer.png';

const ServiceTypeImage = styled.div`
  width: 100%;
  height: 100%;
  margin: ${props => mapper(props.margin, { no: 0, small: '10px', medium: '20px', large: '30px' }, 'no')};
  background: #02978b center center no-repeat;
  border-radius: 4px 0 0 4px;

  ${props =>
    props.aspectRatio &&
    css`
      height: auto;
      padding-top: ${props.aspectRatio};
    `};

  ${props =>
    props.serviceType === FRONT_LOAD &&
    css`
      background-image: url(${props.hasCasters ? frontLoadContainerWithCastersImg : frontLoadContainerImg});
      background-size: 120% auto;
    `};

  ${props =>
    props.serviceType === CART &&
    css`
      background-image: url(${cartContainerImg});
      background-size: 90% auto;
    `};
`;

export default ServiceTypeImage;
