import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { createSelector } from 'reselect';
import { map, filter, sum, size, identity } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import { Panel, Button, Message, Grid, GridColumn } from '../../../core/components/styled';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { getLastUrlSelector } from '../../../core/ducks';
import {
  SERVICE_TYPES,
  CONTAINERS_BY_SERVICE_TYPE,
  WASTE_TYPES,
  RECURRING_FREQUENCIES,
} from '../../../common/constants';
import {
  PageHeader,
  PageTitle,
  PageActions,
  PageBackButton,
  PageBackButtonIcon,
} from '../../../common/components/styled';
import { currency } from '../../../utils/services/formatter';
import { sendShoppingCart } from '../../../quote/ducks';
import { PARTNER_ADMIN, PARTNER_MANAGER, PARTNER_USER } from '../../../account/constants';
import { RoleGuard } from '../../../account/components';
import {
  OpportunityServiceList,
  OpportunityServiceListHeader,
  OpportunityServiceListTitle,
  OpportunityServiceListSubTitle,
  OpportunityServiceDetails,
  OpportunityServiceDetail,
  OpportunityServiceDetailLabel,
  OpportunityServiceDetailValue,
} from '../styled';
import monthlyServicePrice from '../../../common/services/monthlyServicePrice';

const getQualifiedServiceCount = locations =>
  sum(map(locations, ({ services }) => size(filter(services, ({ isDisqualified }) => !isDisqualified))));

const qualifiedServiceCountSelector = createSelector(
  getQualifiedServiceCount,
  identity,
);

class QuotedOpportunityDetailsPage extends PureComponent {
  resendShoppingCart = () => {
    const { match, sendShoppingCart } = this.props;
    const { quoteId } = match.params;

    sendShoppingCart(quoteId)
      .then(() => {
        createSuccessNotification('The shopping cart was resent to the customer.');
      })
      .catch(() => {
        createErrorNotification('There was an error resending the cart.', false);
      });
  };

  render() {
    const { isResendingShoppingCart, locations, previousPageUrl, match, push } = this.props;
    const { quoteId } = match.params;

    return (
      <>
        <PageHeader>
          <PageTitle>
            <PageBackButton to={previousPageUrl}>
              <PageBackButtonIcon />
            </PageBackButton>
            Services
          </PageTitle>
          {qualifiedServiceCountSelector(locations) !== 0 && (
            <PageActions>
              <Button line color="primary" margin="no small no no" onClick={() => push(`/quote/${quoteId}/summary`)}>
                Open quote
              </Button>
              <RoleGuard roles={[PARTNER_ADMIN, PARTNER_MANAGER, PARTNER_USER]}>
                <Button color="primary" disabled={isResendingShoppingCart} onClick={this.resendShoppingCart}>
                  Resend link
                </Button>
              </RoleGuard>
            </PageActions>
          )}
        </PageHeader>

        <Panel>
          {map(locations, ({ services, businessName, address, ...location }) => (
            <OpportunityServiceList key={location.uid}>
              <OpportunityServiceListHeader>
                <OpportunityServiceListTitle>{businessName}</OpportunityServiceListTitle>
                <OpportunityServiceListSubTitle>{address.line1}</OpportunityServiceListSubTitle>
              </OpportunityServiceListHeader>

              {map(
                services,
                ({
                  isDisqualified,
                  serviceType,
                  numberOfContainers,
                  equipmentType,
                  wasteType,
                  recurringFrequency,
                  hasCasters,
                  hasLockbar,
                  price,
                  disqualifiedReason,
                  ...service
                }) => (
                  <OpportunityServiceDetails key={service.uid}>
                    <Grid multiLine>
                      <GridColumn size="2/12" sizePhone="4/12">
                        <OpportunityServiceDetail>
                          <OpportunityServiceDetailLabel>Equipment</OpportunityServiceDetailLabel>
                          <OpportunityServiceDetailValue color={isDisqualified ? 'alert' : 'default'}>
                            {SERVICE_TYPES[serviceType].name}
                          </OpportunityServiceDetailValue>
                        </OpportunityServiceDetail>
                      </GridColumn>

                      <GridColumn size="1/12" sizePhone="4/12">
                        <OpportunityServiceDetail>
                          <OpportunityServiceDetailLabel>QTY</OpportunityServiceDetailLabel>
                          <OpportunityServiceDetailValue color={isDisqualified ? 'alert' : 'default'}>
                            {numberOfContainers}
                          </OpportunityServiceDetailValue>
                        </OpportunityServiceDetail>
                      </GridColumn>

                      <GridColumn size="1/12" sizePhone="4/12">
                        <OpportunityServiceDetail>
                          <OpportunityServiceDetailLabel>Size</OpportunityServiceDetailLabel>
                          <OpportunityServiceDetailValue color={isDisqualified ? 'alert' : 'default'}>
                            {CONTAINERS_BY_SERVICE_TYPE[serviceType][equipmentType].name}
                          </OpportunityServiceDetailValue>
                        </OpportunityServiceDetail>
                      </GridColumn>

                      <GridColumn size="2/12" sizePhone="4/12">
                        <OpportunityServiceDetail>
                          <OpportunityServiceDetailLabel>Material</OpportunityServiceDetailLabel>
                          <OpportunityServiceDetailValue color={isDisqualified ? 'alert' : 'default'}>
                            {WASTE_TYPES[wasteType].name}
                          </OpportunityServiceDetailValue>
                        </OpportunityServiceDetail>
                      </GridColumn>

                      <GridColumn size="2/12" sizePhone="4/12">
                        <OpportunityServiceDetail>
                          <OpportunityServiceDetailLabel>Frequency</OpportunityServiceDetailLabel>
                          <OpportunityServiceDetailValue color={isDisqualified ? 'alert' : 'default'}>
                            {RECURRING_FREQUENCIES[recurringFrequency].name}
                          </OpportunityServiceDetailValue>
                        </OpportunityServiceDetail>
                      </GridColumn>

                      <GridColumn size="2/12" sizePhone="4/12">
                        <OpportunityServiceDetail>
                          <OpportunityServiceDetailLabel>Quoted price</OpportunityServiceDetailLabel>
                          <OpportunityServiceDetailValue color={isDisqualified ? 'alert' : 'default'}>
                            {currency(monthlyServicePrice(price, hasCasters, hasLockbar))}
                          </OpportunityServiceDetailValue>
                        </OpportunityServiceDetail>
                      </GridColumn>

                      <GridColumn size="2/12" sizePhone="12/12">
                        <OpportunityServiceDetail>
                          <OpportunityServiceDetailLabel>Disqualified reason</OpportunityServiceDetailLabel>
                          <OpportunityServiceDetailValue color={isDisqualified ? 'alert' : 'default'}>
                            {disqualifiedReason || '-'}
                          </OpportunityServiceDetailValue>
                        </OpportunityServiceDetail>
                      </GridColumn>
                    </Grid>
                  </OpportunityServiceDetails>
                ),
              )}

              {!size(services) && <Message>There are no services</Message>}
            </OpportunityServiceList>
          ))}
        </Panel>
      </>
    );
  }
}

QuotedOpportunityDetailsPage.propTypes = {
  match: RouterPropTypes.match.isRequired,
  sendShoppingCart: PropTypes.func.isRequired,
  isResendingShoppingCart: PropTypes.bool.isRequired,
  locations: PropTypes.array.isRequired,
  previousPageUrl: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isResendingShoppingCart: state.quote.quote.isCreatingShoppingCart,
  locations: state.opportunities.opportunity.opportunity.locations,
  previousPageUrl: getLastUrlSelector(state.core, '/opportunities/quoted'),
});

const mapDispatchToProps = { sendShoppingCart, push };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(QuotedOpportunityDetailsPage),
);
