import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import { map, filter, size } from 'lodash-es';
import { Panel, Message, TableRow, TableCell, Text } from '../../../core/components/styled';
import { Table, Pagination } from '../../../core/components';
import { getQueryParams, createUrl } from '../../../utils/services/queryParams';
import { localDate, currency } from '../../../utils/services/formatter';
import { SuperAdminGuard } from '../../../account/components';
import { isPartnerSuperAdminSelector } from '../../../account/ducks';
import { SOLD } from '../../../quote/constants';
import { loadOpportunities, resetOpportunities, opportunitiesWithMontlyQuotedPricesSelector } from '../../ducks';

const LIMIT_PER_PAGE = 20;

const countQualifiedServices = services => size(filter(services, ({ isDisqualified }) => !isDisqualified));

const SoldOpportunitiesTableRow = ({
  uid,
  saleDate,
  partnerName,
  locations,
  partnerUserFirstName,
  partnerUserLastName,
  monthlyQuotedPrice,
  tableCellWidths,
  push,
}) => (
  <TableRow onClick={() => push(`/opportunities/${uid}/sold/tracker`)}>
    <TableCell width={tableCellWidths[0]}>{localDate(saleDate)}</TableCell>
    <SuperAdminGuard>
      <TableCell width={tableCellWidths[1]}>{partnerName}</TableCell>
    </SuperAdminGuard>
    <TableCell width={tableCellWidths[2]}>
      {map(locations, ({ businessName, uid }) => (
        <Text block key={uid}>
          {businessName}
        </Text>
      ))}
    </TableCell>
    <TableCell width={tableCellWidths[3]} align="center">
      {size(locations)}
    </TableCell>
    <TableCell width={tableCellWidths[4]} align="center">
      {map(locations, ({ services, uid }) => (
        <Text block key={uid}>
          {countQualifiedServices(services)}
        </Text>
      ))}
    </TableCell>
    <TableCell width={tableCellWidths[5]}>{`${partnerUserFirstName} ${partnerUserLastName}`}</TableCell>
    <TableCell width={tableCellWidths[6]}>{currency(monthlyQuotedPrice)}</TableCell>
  </TableRow>
);

SoldOpportunitiesTableRow.propTypes = {
  uid: PropTypes.string.isRequired,
  saleDate: PropTypes.string.isRequired,
  partnerName: PropTypes.string.isRequired,
  locations: PropTypes.array.isRequired,
  partnerUserFirstName: PropTypes.string.isRequired,
  partnerUserLastName: PropTypes.string.isRequired,
  monthlyQuotedPrice: PropTypes.number.isRequired,
  tableCellWidths: PropTypes.array.isRequired,
  push: PropTypes.func.isRequired,
};

class SoldOpportunitiesSection extends PureComponent {
  componentDidMount() {
    const {
      history,
      loadOpportunities,
      location: { pathname },
    } = this.props;
    this.unlistenHistory = history.listen(location => {
      if (pathname === location.pathname) {
        const { page, limit, sortedBy, sortOrder } = getQueryParams(location.search);
        loadOpportunities(SOLD, page, limit, sortedBy, sortOrder);
      }
    });
  }

  componentWillUnmount() {
    const { resetOpportunities } = this.props;
    this.unlistenHistory();
    resetOpportunities();
  }

  onSortOrderChange = (sortedBy, sortOrder) => {
    const { location, push } = this.props;
    push(createUrl(location.pathname, location.search, { sortedBy, sortOrder }));
  };

  onSortOrderChange = (sortedBy, sortOrder) => {
    const { location, push } = this.props;
    push(createUrl(location.pathname, location.search, { sortedBy, sortOrder }));
  };

  render() {
    const {
      isLoading,
      opportunities,
      total,
      location: { search },
      push,
      isPartnerSuperAdmin,
    } = this.props;
    const { sortOrder, sortedBy } = getQueryParams(search);

    const tableCellWidths = isPartnerSuperAdmin
      ? ['15%', '17%', '17%', '11%', '11%', '17%', '12%']
      : ['20%', undefined, '20%', '17%', '17%', '20%', '16%'];

    const soldOpportunitiesTableCells = [
      { name: 'saleDate', label: 'Date of sale', width: tableCellWidths[0], sortable: true },
      { name: 'partnerName', label: 'Partner name', width: tableCellWidths[1], sortable: true },
      { name: 'businessName', label: 'Business name', width: tableCellWidths[2] },
      { name: 'noOfLocations', label: 'No of locations', width: tableCellWidths[3] },
      { name: 'noOfServices', label: 'No of services', width: tableCellWidths[4] },
      { name: 'userName', label: 'Name of user', width: tableCellWidths[5] },
      { name: 'totalPrice', label: 'Price / month', width: tableCellWidths[6] },
    ];

    if (!isPartnerSuperAdmin) {
      soldOpportunitiesTableCells.splice(1, 1);
    }

    return (
      <Panel padding="no no xxSmall" isLoading={isLoading}>
        {total > 0 && (
          <Table
            cells={soldOpportunitiesTableCells}
            rows={opportunities}
            rowComponent={SoldOpportunitiesTableRow}
            rowProps={{ tableCellWidths, push }}
            sort={this.onSortOrderChange}
            sortOrder={sortOrder}
            sortedBy={sortedBy}
            withClickableRows
          />
        )}
        {total === 0 && <Message padding="sMedium">There are no sold opportunities</Message>}

        {total > LIMIT_PER_PAGE && (
          <Pagination totalResults={total} limitPerPage={LIMIT_PER_PAGE} maxPaginationItems={10} />
        )}
      </Panel>
    );
  }
}
SoldOpportunitiesSection.propTypes = {
  history: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  opportunities: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  loadOpportunities: PropTypes.func.isRequired,
  resetOpportunities: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  location: RouterPropTypes.location.isRequired,
  isPartnerSuperAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isLoading: state.opportunities.opportunities.isLoading,
  opportunities: opportunitiesWithMontlyQuotedPricesSelector(state.opportunities.opportunities),
  total: state.opportunities.opportunities.total,
  isPartnerSuperAdmin: isPartnerSuperAdminSelector(state.account.login),
});

const mapDispatchToProps = { loadOpportunities, resetOpportunities, push };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SoldOpportunitiesSection),
);
