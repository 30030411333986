import React from 'react';
import PropTypes from 'prop-types';
import { NavigationBarIcon, DesktopNavBarItem as ItemContainer } from '../styled';

const NavBarItem = ({ item }) => (
  <ItemContainer to={item.to}>
    <NavigationBarIcon icon={item.icon} />
    {item.caption}
  </ItemContainer>
);

NavBarItem.propTypes = {
  item: PropTypes.shape({
    to: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
  }).isRequired,
};

export default NavBarItem;
