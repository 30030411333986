import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Resolver } from '../../../core/components';
import { PageLoading } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import { getQueryParams } from '../../../utils/services/queryParams';
import { loadUsers } from '../../ducks';
import UsersPage from './UsersPage';

class UsersPageResolver extends PureComponent {
  resolve = () => {
    const {
      loadUsers,
      location: { search },
    } = this.props;
    const { page, limit, sortOrder, sortedBy, searchTerm } = getQueryParams(search);
    return loadUsers(page, limit, sortOrder, sortedBy, searchTerm);
  };

  render() {
    return (
      <>
        <DocumentTitle>Users</DocumentTitle>
        <Resolver successComponent={UsersPage} loadingComponent={PageLoading} resolve={this.resolve} />
      </>
    );
  }
}

UsersPageResolver.propTypes = {
  loadUsers: PropTypes.func.isRequired,
  location: RouterPropTypes.location.isRequired,
};

const mapDispatchToProps = {
  loadUsers,
};

export default withRouter(
  connect(
    undefined,
    mapDispatchToProps,
  )(UsersPageResolver),
);
