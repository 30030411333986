import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SiteName, ServiceName, LocationInfoWindowContainer } from './styled';
import { loadZipcodeDetails } from '../ducks/marketAvailability';

const ServiceLocationInfoWindow = ({ marker, loadZipcodeDetails, zipcodeDetails, isLoadingZipDetails }) => {
  const [availableServices, setAvailableServices] = useState(undefined);
  useEffect(() => {
    if (!marker) return;

    if (marker.zip) {
      loadZipcodeDetails(marker.zip);
    }
  }, []);

  useEffect(() => {
    if (zipcodeDetails && zipcodeDetails.rules && zipcodeDetails.rules[0]) {
      setAvailableServices(zipcodeDetails.rules[0].services);
    }
  }, [zipcodeDetails, isLoadingZipDetails]);

  if (!marker) return null;

  return (
    <LocationInfoWindowContainer isLoadingZipDetails={isLoadingZipDetails}>
      <SiteName>Equipment for zip {marker.zip}</SiteName>
      {availableServices &&
        availableServices.map(service => <ServiceName key={service.service}>{service.service}</ServiceName>)}
    </LocationInfoWindowContainer>
  );
};

/* eslint-disable */
ServiceLocationInfoWindow.propTypes = {
  marker: PropTypes.object,
  loadZipcodeDetails: PropTypes.func.isRequired,
  zipcodeDetails: PropTypes.any,
  isLoadingZipDetails: PropTypes.bool.isRequired,
};
/* eslint-disable */

const mapStateToProps = state => ({
  zipcodeDetails: state.marketConfiguration.marketAvailability.zipcodeDetails,
  isLoadingZipDetails: state.marketConfiguration.marketAvailability.isLoadingZipDetails,
});

const mapDispatchToProps = {
  loadZipcodeDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceLocationInfoWindow);
