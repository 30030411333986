import validator from 'validator';
import { get, identity, has } from 'lodash-es';
import isFalsy from './isFalsy';

const phoneRegex = new RegExp(/^(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/);
const numberRegex = new RegExp(/^[0-9]+(.?)([0-9]?)+$/);
const integerRegex = new RegExp(/^[0-9]+$/);

export const isRequired = value => (!isFalsy(value, { allowZero: true }) ? undefined : 'You can not leave this empty.');
export const isNumber = value => (numberRegex.test(value) ? undefined : 'Please provide a number.');
export const isInteger = value => (integerRegex.test(value) ? undefined : 'Please provide a whole number.');

export const isPositiveNumber = value =>
  numberRegex.test(value) && Number(value) > 0 ? undefined : 'Please provide a positive number.';

export const isGreaterThanField = (field, formatter = identity) => (value, allValues) => {
  const compareWith = get(allValues, field);
  return value < compareWith ? `Must be higher than ${formatter(compareWith)}.` : undefined;
};

export const isEmail = value => (validator.isEmail(value) ? undefined : 'Please provide a valid email.');
export const isPhone = value => (phoneRegex.test(value) ? undefined : 'Please provide a valid phone number.');

export const hasCoordinates = value =>
  has(value, 'latitude') && has(value, 'longitude') ? undefined : 'Please select an address with coordinates.';

export const hasCountry = value =>
  has(value, 'country') && !!value.country ? undefined : 'Please select an address with a country.';
export const hasState = value =>
  has(value, 'state') && !!value.state ? undefined : 'Please select an address with a state.';
export const hasCity = value =>
  has(value, 'city') && !!value.city ? undefined : 'Please select an address with a city.';
export const hasZip = value =>
  has(value, 'zip') && !!value.zip ? undefined : 'Please select and address with a zip code.';

export const hasStreet = value =>
  has(value, 'street') && !!value.street ? undefined : 'Please select an address with a street.';

export const hasStreetNumber = value =>
  has(value, 'streetNumber') && !!value.streetNumber ? undefined : 'Please select an address with a street number.';

const minLength = min => value => (value && value.length < min ? `Must be ${min} characters or more` : undefined);

export const minLength8 = minLength(8);
