import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hasRoleSelector } from '../ducks';

const RoleGuard = ({ hasRole, children }) => (hasRole ? children : null);

RoleGuard.propTypes = {
  hasRole: PropTypes.bool.isRequired,
  roles: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state, { roles }) => ({
  hasRole: hasRoleSelector(state.account.login, roles),
});

export default connect(mapStateToProps)(RoleGuard);
