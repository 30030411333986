import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { get } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import { PanelSection } from '../../../core/components/styled';
import { DocumentTitle } from '../../../common/components';
import { login, resetLogin } from '../../ducks';
import { LoginForm } from '../forms';

class LoginPage extends PureComponent {
  componentWillUnmount() {
    const { resetLogin } = this.props;
    resetLogin();
  }

  onFormSubmit = async ({ email, password }) => {
    const { login, location, push } = this.props;
    const redirectedFrom = get(location, 'state.from.pathname');
    const redirectTo = redirectedFrom !== '/account/logout' ? redirectedFrom : '/opportunities';
    await login(email, password);

    push(redirectTo);
  };

  render() {
    const { isLoggingIn, isLoginFailed, errorMessage } = this.props;
    return (
      <>
        <DocumentTitle>Login</DocumentTitle>
        <PanelSection isLoading={isLoggingIn}>
          <LoginForm isLoginFailed={isLoginFailed} onSubmit={this.onFormSubmit} errorMessage={errorMessage} />
        </PanelSection>
      </>
    );
  }
}

LoginPage.propTypes = {
  isLoggingIn: PropTypes.bool.isRequired,
  isLoginFailed: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  resetLogin: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  location: RouterPropTypes.location.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  isLoggingIn: state.account.login.isLoggingIn,
  isLoginFailed: state.account.login.isLoginFailed,
  login: state.account.login.login,
  resetLogin: state.account.login.resetLogin,
  location: state.router.location,
  errorMessage: state.account.login.errorMessage,
});

const mapDispatchToProps = {
  login,
  resetLogin,
  push,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(LoginPage),
);
