import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash-es';
import memoizeOne from 'memoize-one';
import SearchInput, { createFilter } from 'react-search-input';
import { Panel, PanelSection, PanelContent, PanelContainer, SearchInputStyled } from '../../../core/components/styled';
import { loadZipcodesAvailability, resetMarketAvailability as reset } from '../../ducks/marketAvailability';
import ClusterMap from '../../../core/components/ClusterMap';
import ServiceLocationInfoWindow from '../ServiceLocationInfoWindow';
import usZipcodes from '../../constants/usZipcodes';
import { PageTitle } from '../../../common/components/styled';

const legendItems = [
  {
    icon: 'green',
    label: 'Open',
  },
  {
    icon: 'red',
    label: 'Franchised',
  },
];

const getFilteredLocations = (locations, searchLocation) => {
  const KEYS_TO_FILTERS = ['zip'];
  const filteredList = locations.filter(createFilter(searchLocation, KEYS_TO_FILTERS));
  return filteredList;
};

const memoizedFilteredLocations = memoizeOne(getFilteredLocations);

class MarketAvailabilityPage extends PureComponent {
  state = {
    searchLocation: '',
    bounds: {},
    zipcodeList: [],
  };

  componentDidMount() {
    const { loadZipcodesAvailability } = this.props;
    loadZipcodesAvailability();
  }

  componentDidUpdate(prevProps) {
    const { marketAvailabilityZips } = this.props;

    if (marketAvailabilityZips !== prevProps.marketAvailabilityZips && !isEmpty(marketAvailabilityZips)) {
      this.filterZipcodes(marketAvailabilityZips);
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  filterZipcodes = rubiconZipCodeList => {
    const zipcodeList = rubiconZipCodeList.zipCodes
      .map(rubiZip => {
        const getGeocodeInfo = usZipcodes.Zips.find(zip => zip.ZipCode === rubiZip.zipCode);

        // invalid zipcodes
        if (!getGeocodeInfo) {
          return {
            zip: rubiZip.zipCode,
            id: rubiZip.zipCode,
            lat: 0,
            lng: -1,
            isChecked: !rubiZip.franchise,
          };
        }

        const formattedCodes = {
          zip: rubiZip.zipCode,
          id: `${rubiZip.zipCode}:${getGeocodeInfo.Lat}:${getGeocodeInfo.Long}`,
          lat: getGeocodeInfo.Lat,
          lng: getGeocodeInfo.Long,
          isChecked: !rubiZip.franchise,
        };

        return formattedCodes;
      })
      .filter(zipcode => !isEmpty(zipcode));

    this.setState({ zipcodeList });
  };

  searchUpdated = term => {
    this.setState({ searchLocation: term });
  };

  getBounds = mapBounds => {
    const { bounds } = this.state;
    if (mapBounds !== bounds) {
      this.setState({ bounds: mapBounds });
    }
  };

  render() {
    const { isLoading } = this.props;
    const { searchLocation, zipcodeList } = this.state;
    const filteredZipcodeList = memoizedFilteredLocations(zipcodeList, searchLocation);

    return (
      <PanelContainer single>
        <Panel noBackground>
          <PanelContent padding="small">
            <PageTitle>Market Availability</PageTitle>
            <PanelSection>
              <SearchInputStyled>
                <SearchInput placeholder="find Zip code" className="search-input" onChange={this.searchUpdated} />
              </SearchInputStyled>
              <ClusterMap
                markersList={filteredZipcodeList}
                getBounds={this.getBounds}
                onSearchBounds={searchLocation.length}
                InfoWindowContent={ServiceLocationInfoWindow}
                clickEnabled
                legendItems={legendItems}
                isLoadingLoactions={isLoading}
                isCheckedClusters
                height="600px"
              />
            </PanelSection>
          </PanelContent>
        </Panel>
      </PanelContainer>
    );
  }
}
/* eslint-disable */
MarketAvailabilityPage.propTypes = {
  marketAvailabilityZips: PropTypes.any,
  reset: PropTypes.func.isRequired,
  loadZipcodesAvailability: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};
/* eslint-disable  */

const mapStateToProps = state => {
  const stateRoute = state.marketConfiguration.marketAvailability;

  return {
    marketAvailabilityZips: stateRoute.marketAvailabilityZips,
    isLoading: stateRoute.isLoading,
  };
};

const mapDispatchToProps = {
  reset,
  loadZipcodesAvailability,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MarketAvailabilityPage);
