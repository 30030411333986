import React from 'react';
import PropTypes from 'prop-types';
import { MobileNavBarPrimaryContainer, MobileNavBarHamburger, MobileNavBarTitle } from '../styled/MobileNavBar';

const MobileTopNavBar = ({ openHamburger, pageName }) => (
  <MobileNavBarPrimaryContainer>
    <MobileNavBarHamburger onClick={openHamburger} />
    <MobileNavBarTitle>{pageName}</MobileNavBarTitle>
  </MobileNavBarPrimaryContainer>
);
MobileTopNavBar.propTypes = {
  openHamburger: PropTypes.func.isRequired,
  pageName: PropTypes.string.isRequired,
};

export default MobileTopNavBar;
