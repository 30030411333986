import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Switch, Redirect } from 'react-router';
import { Panel, PanelSection, Tabs, Tab, Button } from '../../../core/components/styled';
import { PageHeader, PageTitle, PageActions } from '../../../common/components/styled';
import { AuthRoute } from '../../../account/components';
import { exportOpportunities } from '../../ducks';
import {
  ContactedOpportunitiesSectionResolver,
  QuotedOpportunitiesSectionResolver,
  SoldOpportunitiesSectionResolver,
  DisqualifiedOpportunitiesSectionResolver,
} from '../opportunitiesPageSections';
import { NonMobile } from '../../../common/components/Responsive';

class OpporunitiesPage extends PureComponent {
  exportOpportunities = () => {
    const { exportOpportunities } = this.props;
    exportOpportunities();
  };

  render() {
    const { isExporting, isLoading } = this.props;

    return (
      <>
        <PageHeader>
          <NonMobile>
            <PageTitle>Opportunities</PageTitle>
          </NonMobile>

          <PageActions>
            <Button color="primary" disabled={isLoading || isExporting} onClick={this.exportOpportunities}>
              Export
            </Button>
          </PageActions>
        </PageHeader>

        <Panel isLoading={isExporting}>
          <PanelSection>
            <Tabs>
              <Tab as={NavLink} to="/opportunities/contacted">
                Contacted
              </Tab>
              <Tab as={NavLink} to="/opportunities/quoted">
                Quoted
              </Tab>
              <Tab as={NavLink} to="/opportunities/sold">
                Sold
              </Tab>
              <Tab as={NavLink} to="/opportunities/disqualified">
                Disqualified
              </Tab>
            </Tabs>
          </PanelSection>

          <Switch>
            <AuthRoute exact path="/opportunities/contacted" component={ContactedOpportunitiesSectionResolver} />
            <AuthRoute exact path="/opportunities/quoted" component={QuotedOpportunitiesSectionResolver} />
            <AuthRoute exact path="/opportunities/sold" component={SoldOpportunitiesSectionResolver} />
            <AuthRoute exact path="/opportunities/disqualified" component={DisqualifiedOpportunitiesSectionResolver} />
            <Redirect to="/opportunities/contacted" />
          </Switch>
        </Panel>
      </>
    );
  }
}

OpporunitiesPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isExporting: PropTypes.bool.isRequired,
  exportOpportunities: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isLoading: state.opportunities.opportunities.isLoading,
  isExporting: state.opportunities.opportunities.isExporting,
  total: state.opportunities.opportunities.total,
});

const mapDispatchToProps = { exportOpportunities };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OpporunitiesPage);
