import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import uuid from 'uuid';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import { formValueSelector, isValid, submit } from 'redux-form';
import { createSelector } from 'reselect';
import { identity } from 'lodash-es';
import { createErrorNotification } from '../../../core/services/createNotification';
import {
  Panel,
  Button,
  ButtonLink,
  PanelSection,
  PanelSectionHeader,
  PanelSectionTitle,
  MapContainer,
  FormError,
} from '../../../core/components/styled';
import { PageHeader, PageTitle, PageActions } from '../../../common/components/styled';
import { getQueryParams } from '../../../utils/services/queryParams';
import { NonMobile } from '../../../common/components/Responsive';
import { SERVICE_PROVIDERS } from '../../constants';
import { updateLocation, saveQuote, locationsSelector, locationCountSelector, addLocation } from '../../ducks';
import { LocationEditorForm } from '../forms';
import confirm from '../../../core/services/confirm';
import loadFranchise from '../../../common/services/loadFranchise';
import { isPhoneNumberVerifiedSelector } from '../../../account/ducks/login';
import ClusterMap from '../../../core/components/ClusterMap';

const addressesSelector = createSelector(
  address => (address ? [address] : []),
  identity,
);

class LocationEditorPage extends PureComponent {
  state = { isLoadingFranchise: false };

  onFormSubmit = async formData => {
    const {
      match,
      location: { search },
    } = this.props;
    const { locationId: existingLocationId } = match.params;
    const { zip } = formData.address;
    const { serviceProvider } = getQueryParams(search);

    if (!existingLocationId && !SERVICE_PROVIDERS[serviceProvider]) {
      const location = {
        ...formData,
        disqualifiedServiceProvider: serviceProvider,
        isDisqualified: true,
        disqualifiedReason: `Can not sell services to customers of ${serviceProvider}.`,
      };

      await this.addLocation(location);
      return;
    }

    this.setState({ isLoadingFranchise: true });

    const franchiseMap = await loadFranchise(formData.address);

    this.setState({ isLoadingFranchise: false });
    const isFranchisedLocation = franchiseMap[formData.address.line1];

    const isNewService = Number(serviceProvider) === 0;

    if (!existingLocationId && isFranchisedLocation) {
      const location = {
        ...formData,
        serviceProviderId: isNewService ? undefined : serviceProvider,
        isDisqualified: true,
        disqualifiedReason: `Zip code ${zip} is a franchised area.`,
      };

      await this.addLocation(location);
      return;
    }

    if (serviceProvider && !SERVICE_PROVIDERS[serviceProvider]) {
      const location = { ...formData };

      await this.updateLocation(location);
      return;
    }

    if (isFranchisedLocation) {
      const location = {
        ...formData,
        isDisqualified: true,
        disqualifiedReason: `Zip code ${zip} is a franchised area.`,
      };

      await this.updateLocation(location);
      return;
    }

    if (existingLocationId) {
      const location = {
        ...formData,
        isDisqualified: false,
        disqualifiedReason: undefined,
      };

      await this.updateLocation(location);
      return;
    }

    const location = {
      ...formData,
      serviceProviderId: isNewService ? undefined : serviceProvider,
    };

    await this.addLocation(location);
  };

  addLocation = async location => {
    const { addLocation, locationCount, saveQuote, push } = this.props;
    const locationId = uuid();
    addLocation(locationId, location);

    const quote = await saveQuote();

    if (location.isDisqualified && locationCount === 0) {
      this.confirmToContinue(quote.id, `${location.disqualifiedReason} Do you want to continue with this quote?`);
      return;
    }

    if (location.isDisqualified && locationCount !== 0) {
      createErrorNotification(`${location.disqualifiedReason} Please choose another address / service provider`);
      push(`/quote/${quote.id}/choose-provider`);
      return;
    }

    push(`/quote/${quote.id}/locations/${locationId}/services`);
  };

  updateLocation = async location => {
    const { updateLocation, locationCount, saveQuote, push } = this.props;
    updateLocation(location.id, location);

    const quote = await saveQuote();

    if (location.isDisqualified && locationCount === 0) {
      this.confirmToContinue(quote.id, `${location.disqualifiedReason} Do you want to continue with this quote?`);
      return;
    }

    if (location.isDisqualified && locationCount !== 0) {
      createErrorNotification(`${location.disqualifiedReason} Please choose another address / service provider`);
      push(`/quote/${quote.id}/choose-provider`);
      return;
    }

    push(`/quote/${quote.id}/locations/${location.id}/services`);
  };

  confirmToContinue = async (quoteId, message) => {
    const { push } = this.props;
    const isConfirmed = await confirm('Do you want to continue?', message);
    if (isConfirmed) {
      push(`/quote/${quoteId}/choose-provider`);
    } else {
      push('/opportunities/disqualified');
    }
  };

  submitForm = () => {
    const { submit } = this.props;
    submit('locationEditor');
  };

  render() {
    const { isLoadingFranchise } = this.state;
    const { quoteId, locations, existingLocation, address, isSaving, isFormValid, isPhoneNumberVerified } = this.props;
    const addresses = addressesSelector(address);
    return (
      <>
        <PageHeader>
          <PageTitle>Location</PageTitle>
          <PageActions>
            {!!locations.length && (
              <ButtonLink
                line
                color="primary"
                margin="no small no no"
                marginTablet="no xSmall no no"
                marginPhone="no xxSmall no no"
                disabled={isSaving || isLoadingFranchise}
                to={`/quote/${quoteId}/locations/${locations[0].id}/services`}
              >
                Cancel
              </ButtonLink>
            )}

            <Button
              color="primary"
              disabled={!isFormValid || isLoadingFranchise || isSaving || !isPhoneNumberVerified}
              onClick={this.submitForm}
            >
              Next
            </Button>
          </PageActions>
        </PageHeader>

        <Panel horizontal tabletVerticalReverse isLoading={isLoadingFranchise || isSaving}>
          <PanelSection
            padding="medium medium xLarge"
            paddingTablet="small small medium"
            paddingPhone="xSmall xSmall small"
            size="50%"
          >
            <NonMobile>
              <PanelSectionHeader marginPhone="no no xxSmall">
                <PanelSectionTitle>Location details</PanelSectionTitle>
              </PanelSectionHeader>
            </NonMobile>
            {!isPhoneNumberVerified && (
              <FormError>
                Please confirm your phone number in the account profile section in order to proceed.
              </FormError>
            )}
            <LocationEditorForm initialValues={existingLocation} onSubmit={this.onFormSubmit} />
          </PanelSection>

          <PanelSection size="50%">
            <MapContainer size="large">
              <ClusterMap
                styled
                markersList={addresses.map(address => ({
                  id: address.zip,
                  lat: address.latitude,
                  lng: address.longitude,
                  zip: address.zip,
                }))}
                height="500px"
              />
            </MapContainer>
          </PanelSection>
        </Panel>
      </>
    );
  }
}

LocationEditorPage.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  isFormValid: PropTypes.bool.isRequired,
  quoteId: PropTypes.string,
  locations: PropTypes.array.isRequired,
  locationCount: PropTypes.number.isRequired,
  existingLocation: PropTypes.object,
  address: PropTypes.object,
  match: RouterPropTypes.match.isRequired,
  location: RouterPropTypes.location.isRequired,
  addLocation: PropTypes.func.isRequired,
  updateLocation: PropTypes.func.isRequired,
  saveQuote: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  isPhoneNumberVerified: PropTypes.bool.isRequired,
};

LocationEditorPage.defaultProps = {
  quoteId: undefined,
  existingLocation: undefined,
  address: undefined,
};

const formSelector = formValueSelector('locationEditor');
const locationsFilter = { mustBeQualified: true, mustHaveAddress: true };

const mapStateToProps = (state, ownProps) => ({
  isSaving: state.quote.quote.isSaving,
  isFormValid: isValid('locationEditor')(state),
  quoteId: state.quote.quote.id,
  address: formSelector(state, 'address'),
  locations: locationsSelector(state.quote.quote, locationsFilter),
  locationCount: locationCountSelector(state.quote.quote),
  existingLocation: state.quote.quote.locations[ownProps.match.params.locationId],
  isPhoneNumberVerified: isPhoneNumberVerifiedSelector(state.account.login),
});

const mapDispatchToProps = {
  addLocation,
  updateLocation,
  saveQuote,
  submit,
  push,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(LocationEditorPage),
);
