import GoogleAnalyticsGtag, { trackPageView } from '@redux-beacon/google-analytics-gtag';
import { LOCATION_CHANGE } from 'react-router-redux';
import { createMiddleware } from 'redux-beacon';
import { getBaseUrl } from './environment';

const eventsMap = {
  [LOCATION_CHANGE]: trackPageView(action => ({
    path: action.payload.pathname,
    location: `${getBaseUrl()}${action.payload.pathname}`,
    title: '',
  })),
};

const ga = GoogleAnalyticsGtag('UA-134145922-1');

const gaMiddleware = createMiddleware(eventsMap, ga);

export default gaMiddleware;
