import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, fieldInputPropTypes } from 'redux-form';
import { map } from 'lodash-es';
import { Select } from '../../core/components';
import { isRequired } from '../../utils/services/validator';
import { BUSINESS_TYPES } from '../constants';

const businessTypeOptions = map(BUSINESS_TYPES, businessType => ({
  label: businessType.name,
  value: businessType.id,
}));

class BusinessTypeSelect extends PureComponent {
  onChange = (event, value) => {
    const { input } = this.props;
    input.onChange(value);
  };

  render() {
    const { input } = this.props;

    return (
      <Field
        name={input.name}
        component={Select}
        label="Business type"
        options={businessTypeOptions}
        validate={[isRequired]}
        onChange={this.onChange}
      >
        <option value="" />
      </Field>
    );
  }
}

BusinessTypeSelect.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
};

export default BusinessTypeSelect;
