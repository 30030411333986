import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardDetails,
  CardImageContainer,
  CardTitle,
  CardDetail,
  CardDetailLabel,
  CardDetailValue,
  CardActions,
  CardAction,
  CardActionIcon,
  CardExtraDetails,
  CardExtraDetail,
  CardExtraDetailLabel,
  CardExtraDetailValue,
  CardError,
  Button,
} from '../../core/components/styled';
import { SERVICE_TYPES, WASTE_TYPES, CONTAINERS_BY_SERVICE_TYPE, RECURRING_FREQUENCIES } from '../../common/constants';
import { NonMobile } from '../../common/components/Responsive';
import { enumeration, currency } from '../../utils/services/formatter';
import { ServiceTypeImage } from '../../common/components/styled';
import monthlyServicePrice from '../../common/services/monthlyServicePrice';

class ServiceCard extends PureComponent {
  editService = () => {
    const { id, editService } = this.props;
    editService(id);
  };

  deleteService = event => {
    event.stopPropagation();
    const { id, deleteService } = this.props;
    deleteService(id);
  };

  render() {
    const {
      numberOfContainers,
      wasteType,
      containerSize,
      recurringFrequency,
      serviceType,
      hasCasters,
      hasLockbar,
      showPrice,
      smallCard,
      price,
      isPartnerSuperAdmin,
      isDisqualified,
      disqualifiedReason,
      isSelected,
      note,
    } = this.props;

    return (
      <Card isSelected={isSelected} hasError={isDisqualified}>
        <NonMobile>
          <CardImageContainer>
            <ServiceTypeImage serviceType={serviceType} hasCasters={hasCasters} />
          </CardImageContainer>
        </NonMobile>

        <CardDetails>
          <CardTitle hasRebate={price && price.pickupPartnerRebate} smallCard={smallCard}>
            {SERVICE_TYPES[serviceType].name}
          </CardTitle>

          <CardDetail width="50%">
            <CardDetailLabel>Quantity</CardDetailLabel>
            <CardDetailValue>{numberOfContainers}</CardDetailValue>
          </CardDetail>

          <CardDetail width="50%">
            <CardDetailLabel>Material</CardDetailLabel>
            <CardDetailValue>{WASTE_TYPES[wasteType].name}</CardDetailValue>
          </CardDetail>

          <CardDetail width="50%">
            <CardDetailLabel>Container size</CardDetailLabel>
            <CardDetailValue>{CONTAINERS_BY_SERVICE_TYPE[serviceType][containerSize].name}</CardDetailValue>
          </CardDetail>

          <CardDetail width="50%">
            <CardDetailLabel>Service frequency</CardDetailLabel>
            <CardDetailValue>{RECURRING_FREQUENCIES[recurringFrequency].name}</CardDetailValue>
          </CardDetail>

          {(hasCasters || hasLockbar) && (
            <CardDetail width="50%">
              <CardDetailLabel>Extras</CardDetailLabel>
              <CardDetailValue>{enumeration(hasCasters && 'Casters', hasLockbar && 'Lockbar')}</CardDetailValue>
            </CardDetail>
          )}

          <CardDetail width={hasCasters || hasLockbar ? '50%' : '100%'}>
            <CardDetailLabel>Notes</CardDetailLabel>
            <CardDetailValue>{note}</CardDetailValue>
          </CardDetail>

          <Button
            smallSize
            line
            margin="no no small no"
            color="primary"
            onClick={isPartnerSuperAdmin ? undefined : this.editService}
            disabled={isPartnerSuperAdmin}
          >
            EDIT
          </Button>
        </CardDetails>

        {!!showPrice && !!price && !!price.pickup && (
          <CardExtraDetails smallCard={smallCard}>
            {price.pickupPartnerRebate && price.pickupPartnerRebate !== 0 ? (
              <CardExtraDetail>
                <CardExtraDetail>
                  <CardExtraDetailValue smallCard={smallCard}>
                    {currency(price.pickupPartnerRebate)}
                  </CardExtraDetailValue>
                  <CardExtraDetailLabel smallCard={smallCard}>rebate</CardExtraDetailLabel>
                </CardExtraDetail>
              </CardExtraDetail>
            ) : null}
            <CardExtraDetail>
              <CardExtraDetailValue smallCard={smallCard}>
                {currency(monthlyServicePrice(price, hasCasters, hasLockbar))}
              </CardExtraDetailValue>
              <CardExtraDetailLabel smallCard={smallCard}>per month</CardExtraDetailLabel>
            </CardExtraDetail>
          </CardExtraDetails>
        )}

        {!isPartnerSuperAdmin && (
          <CardActions>
            <CardAction onClick={this.deleteService}>
              <CardActionIcon icon="delete" />
            </CardAction>
          </CardActions>
        )}

        {isDisqualified && <CardError>{disqualifiedReason}</CardError>}
      </Card>
    );
  }
}

ServiceCard.propTypes = {
  id: PropTypes.string.isRequired,
  serviceType: PropTypes.string.isRequired,
  numberOfContainers: PropTypes.number.isRequired,
  containerSize: PropTypes.number.isRequired,
  recurringFrequency: PropTypes.number.isRequired,
  wasteType: PropTypes.string.isRequired,
  hasCasters: PropTypes.bool,
  hasLockbar: PropTypes.bool,
  isPartnerSuperAdmin: PropTypes.bool.isRequired,
  isDisqualified: PropTypes.bool.isRequired,
  disqualifiedReason: PropTypes.string,
  showPrice: PropTypes.bool,
  smallCard: PropTypes.bool,
  price: PropTypes.object,
  isSelected: PropTypes.bool.isRequired,
  editService: PropTypes.func.isRequired,
  deleteService: PropTypes.func.isRequired,
  note: PropTypes.string,
};

ServiceCard.defaultProps = {
  hasCasters: undefined,
  hasLockbar: undefined,
  disqualifiedReason: undefined,
  price: undefined,
  showPrice: false,
  smallCard: false,
  note: undefined,
};

export default ServiceCard;
