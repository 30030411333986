import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { logout } from '../../ducks';

class LogoutPage extends PureComponent {
  constructor(props) {
    super(props);
    props.logout();
  }

  render() {
    const { location } = this.props;
    return <Redirect to={{ pathname: '/account/login', state: { from: location } }} />;
  }
}

LogoutPage.propTypes = {
  logout: PropTypes.func.isRequired,
  location: RouterPropTypes.location.isRequired,
};

const mapDispatchToProps = { logout };

export default connect(
  undefined,
  mapDispatchToProps,
)(LogoutPage);
