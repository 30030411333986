import React from 'react';
import { Switch, Redirect } from 'react-router';
import { AuthRoute } from '../../account/components';
import { OpportunitiesPage, QuotedOpportunityDetailsPageResolver, SoldOpportunityDetailsPageResolver } from './pages';

const OpportuntiesRouter = () => (
  <Switch>
    <AuthRoute path="/opportunities/:quoteId/quoted" component={QuotedOpportunityDetailsPageResolver} />
    <AuthRoute path="/opportunities/:quoteId/sold/tracker" component={SoldOpportunityDetailsPageResolver} />
    <AuthRoute path="/opportunities" component={OpportunitiesPage} />
    <Redirect to="/opportunities" />
  </Switch>
);

export default OpportuntiesRouter;
