import update from 'immutability-helper';
import { createSelector } from 'reselect';
import { reduce, sumBy, map, identity } from 'lodash-es';
import { loadOpportunities as doLoadOpportunities } from '../services/opportunity';
import doExportOpportunities from '../services/exportOpportunities';
import monthlyServicePrice from '../../common/services/monthlyServicePrice';
import { createErrorNotification } from '../../core/services/createNotification';

// Actions
const START_LOAD = 'opportunities/opportunities/START_LOAD';
const COMPLETE_LOAD = 'opportunities/opportunities/COMPLETE_LOAD';
const FAIL_LOAD = 'opportunities/opportunities/FAIL_LOAD';
const START_EXPORT = 'opportunities/opportunities/START_EXPORT';
const COMPLETE_EXPORT = 'opportunities/opportunities/COMPLETE_EXPORT';
const RESET = 'opportunities/opportunities/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isExporting: false,
  opportunities: undefined,
  total: undefined,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          opportunities: action.opportunities,
          total: action.total,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case START_EXPORT:
      return update(state, {
        $merge: {
          isExporting: true,
        },
      });

    case COMPLETE_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (opportunities, total) => ({
  type: COMPLETE_LOAD,
  opportunities,
  total,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startExport = () => ({
  type: START_EXPORT,
});

const completeExport = () => ({
  type: COMPLETE_EXPORT,
});

export const loadOpportunities = (status, page, limit, sortedBy, sortOrder) => dispatch => {
  dispatch(startLoad());
  const loadOpportunitiesPromise = doLoadOpportunities(status, page, limit, sortedBy, sortOrder);
  loadOpportunitiesPromise
    .then(({ opportunities, total }) => dispatch(completeLoad(opportunities, total)))
    .catch(() => dispatch(failLoad()));

  return loadOpportunitiesPromise;
};

export const exportOpportunities = () => dispatch => {
  dispatch(startExport());
  const exportOpportunitiesPromise = doExportOpportunities();
  exportOpportunitiesPromise
    .then(() => dispatch(completeExport()))
    .catch(() => {
      dispatch(completeExport());
      createErrorNotification('Something went wrong! Please try again later!');
    });

  return exportOpportunitiesPromise;
};

export const resetOpportunities = () => ({
  type: RESET,
});

// Selectors
const getOpportunitiesWithMontlyQuotedPrices = opportunitiesState =>
  map(opportunitiesState.opportunities, opportunity => {
    const { locations } = opportunity;
    const monthlyQuotedPrice = reduce(
      locations,
      (totalServicesPrice, location) => {
        const { services } = location;
        return (
          totalServicesPrice +
          sumBy(services, ({ price, hasCasters, hasLockbar }) => monthlyServicePrice(price, hasCasters, hasLockbar))
        );
      },
      0,
    );
    return { ...opportunity, monthlyQuotedPrice };
  });

export const opportunitiesWithMontlyQuotedPricesSelector = createSelector(
  getOpportunitiesWithMontlyQuotedPrices,
  identity,
);
