import React from 'react';
import PropTypes from 'prop-types';
import { MobileNavItem } from '../styled/MobileNavBar';
import { NavigationBarIcon } from '../styled';

const MobileNavBarItem = ({ item, onClick }) => (
  <MobileNavItem to={item.to} onClick={onClick}>
    <NavigationBarIcon icon={item.icon} />
    {item.caption}
  </MobileNavItem>
);

MobileNavBarItem.propTypes = {
  item: PropTypes.shape({
    to: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default MobileNavBarItem;
