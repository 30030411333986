import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, fieldInputPropTypes } from 'redux-form';
import { createSelector } from 'reselect';
import { map, identity } from 'lodash-es';
import { Select } from '../../core/components';
import { isRequired } from '../../utils/services/validator';
import { RECURRING_FREQUENCIES, RECURRING_FREQUENCIES_BY_SERVICE_TYPE } from '../constants';

const recurringFrequencyOptionsSelector = createSelector(
  (serviceType, acceptedRecurringFrequencies) => {
    const recurringFrequencies = acceptedRecurringFrequencies || RECURRING_FREQUENCIES_BY_SERVICE_TYPE[serviceType];

    return map(recurringFrequencies, recurringFrequency => ({
      label: RECURRING_FREQUENCIES[recurringFrequency].name,
      value: recurringFrequency,
    }));
  },
  identity,
);

class RecurringFrequencySelect extends PureComponent {
  state = {};

  static getDerivedStateFromProps(props) {
    const {
      acceptedRecurringFrequencies,
      input: { value, onChange },
    } = props;

    if (
      acceptedRecurringFrequencies &&
      acceptedRecurringFrequencies.length &&
      acceptedRecurringFrequencies.indexOf(value) === -1 &&
      value
    ) {
      onChange('');
    }
    return null;
  }

  onChange = (event, value) => {
    const { input } = this.props;
    input.onChange(value);
  };

  render() {
    const {
      input: { name },
      serviceType,
      acceptedRecurringFrequencies,
    } = this.props;

    const recurringFrequencyOptions = recurringFrequencyOptionsSelector(serviceType, acceptedRecurringFrequencies);

    return (
      <Field
        name={name}
        component={Select}
        label="Service frequency"
        options={recurringFrequencyOptions}
        normalize={Number}
        validate={[isRequired]}
        onChange={this.onChange}
      >
        <option value="" />
      </Field>
    );
  }
}

RecurringFrequencySelect.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  serviceType: PropTypes.string,
  acceptedRecurringFrequencies: PropTypes.arrayOf(PropTypes.number),
};

RecurringFrequencySelect.defaultProps = {
  serviceType: undefined,
  acceptedRecurringFrequencies: undefined,
};

export default RecurringFrequencySelect;
