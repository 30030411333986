import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MobileNavOvershadowFull } from '../styled/MobileNavBar';
import MobileTopNavBar from './MobileTopNavBar';
import MobileSideNavBar from './MobileSideNavBar';
import { mobilePageTitleSelector } from '../../ducks/routing';

class MobileNavBar extends Component {
  state = {
    isSideBarVisible: false,
  };

  openHamburger = () => {
    this.setState({ isSideBarVisible: true });
  };

  closeHamburger = () => {
    this.setState({ isSideBarVisible: false });
  };

  onBarClick = event => {
    event.stopPropagation();
  };

  render() {
    const { isSideBarVisible } = this.state;
    const { pageName, username, isPartnerSuperAdmin, openUserProfileModal } = this.props;

    return (
      <>
        {!isSideBarVisible && <MobileTopNavBar openHamburger={this.openHamburger} pageName={pageName} />}
        {isSideBarVisible && (
          <MobileNavOvershadowFull onClick={this.closeHamburger}>
            <MobileSideNavBar
              username={username}
              isPartnerSuperAdmin={isPartnerSuperAdmin}
              closeHamburger={this.closeHamburger}
              openUserProfileModal={openUserProfileModal}
            />
          </MobileNavOvershadowFull>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    pageName: mobilePageTitleSelector(state.router.location),
  };
}

MobileNavBar.propTypes = {
  username: PropTypes.string.isRequired,
  isPartnerSuperAdmin: PropTypes.bool.isRequired,
  pageName: PropTypes.string.isRequired,
  openUserProfileModal: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(MobileNavBar);
