import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { mapper, sizeMapper, alignMapper, media } from '../../../utils/styles';
import { loadingOverlay } from '../../styles';
import { Icon } from '..';

export const PanelSearchIcon = styled(Icon).attrs({ icon: 'search' })`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  color: ${props => props.theme.gray};
`;

export const PanelSearchInput = styled.input`
  flex: 1;
  padding: 0;
  background-color: transparent;
  outline: none;
  border: none;
  line-height: 22px;
  font-size: 18px;

  &::placeholder {
    text-transform: capitalize;
    color: ${props => props.theme.grayDark};
  }
`;

export const PanelSearch = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 0;
`;

export const PanelSectionTitle = styled.h2`
  text-transform: capitalize;
  font-weight: ${props => props.theme.fontWeightNormal};
  font-size: 24px;

  ${media.tablet`
    font-size: 18px;
  `}
`;

export const PanelSectionActions = styled.div``;

export const PanelSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${props => sizeMapper(props.margin, 'no no sMedium')};

  ${props =>
    props.marginTablet &&
    css`
      ${media.tablet`
        margin: ${props => sizeMapper(props.marginTablet)};
      `}
    `}

  ${props =>
    props.marginPhone &&
    css`
      ${media.phone`
        margin: ${props => sizeMapper(props.marginPhone)};
      `}
    `}
`;

export const PanelSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: ${props => sizeMapper(props.margin, 'no')};
  padding: ${props => sizeMapper(props.padding, 'no')};

  ${props =>
    props.paddingTablet &&
    css`
      ${media.tablet`
        padding: ${props => sizeMapper(props.paddingTablet)};
      `}
    `}

  ${props =>
    props.paddingPhone &&
    css`
      ${media.phone`
        padding: ${props => sizeMapper(props.paddingPhone)};
      `}
    `}

  ${props =>
    props.align &&
    css`
      align-items: ${alignMapper(props)};
    `};

  ${props =>
    props.size &&
    css`
      flex: ${props.size};
    `};

  ${props =>
    props.border &&
    css`
      border: solid ${props => props.theme.grayLight};
      border-width: ${mapper(props.border, {
        top: '1px 0 0',
        right: '0 1px 0 0',
        bottom: '0 0 1px 0',
        left: '0 0 0 1px',
      })};
    `};

  ${props =>
    props.isLoading &&
    css`
      ${loadingOverlay('24px')};
    `};
`;

export const PanelSectionLoading = styled.div`
  position: relative;
  padding: 30px 0;
  ${loadingOverlay('24px')};
`;

export const Panel = styled.div`
  position: relative;
  margin: ${props => sizeMapper(props.margin, 'no')};
  padding: ${props => sizeMapper(props.padding, 'no')};
  background-color: #fff;
  box-shadow: 0 2px 10px ${transparentize(0.95, '#000')};

  ${props =>
    props.paddingTablet &&
    css`
      ${media.tablet`
        padding: ${props => sizeMapper(props.paddingTablet)};
      `}
    `}

  ${props =>
    props.noBackground &&
    css`
      background: transparent;
      box-shadow: none;
    `};

  ${props =>
    props.paddingPhone &&
    css`
      ${media.phone`
        padding: ${props => sizeMapper(props.paddingPhone)};
      `}
    `}

  ${props =>
    props.horizontal &&
    css`
      display: flex;
    `};

  ${props =>
    props.tabletVertical &&
    css`
      ${media.tablet`
        display:flex;
        flex-direction: column;
      `};
    `};

  ${props =>
    props.tabletVerticalReverse &&
    css`
      ${media.tablet`
          display:flex;
          flex-direction: column-reverse;
        `};
    `};

  ${props =>
    props.isLoading &&
    css`
      ${loadingOverlay('24px')};
    `};
`;

export const PanelContent = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding: 40px 60px;

  ${props =>
    props.wide &&
    css`
      max-width: 720px;
    `};

  ${props =>
    props.fullWidth &&
    css`
      max-width: 100%;
    `};

  ${props =>
    props.padding &&
    css`
      padding: ${mapper(props.padding, { no: 0, small: '20px', medium: '40px', large: '60px' })};
    `};
`;

export const PanelContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-grow: 1;
  position: relative;
  margin: ${props => mapper(props.margin, { no: 0, small: '10px', medium: '20px', large: '30px' }, 'no')};

  ${props =>
    props.single &&
    css`
      ${Panel} {
        width: 100%;
        max-width: 1160px;
      }

      ${PanelContent} {
        max-width: 100%;
      }
    `};

  ${props =>
    props.isLoading &&
    css`
      ${loadingOverlay('26px')};
    `};
`;
