import { identity } from 'lodash-es';
import { createSelector } from 'reselect';

const getCustomerEditorFormInitialValues = qouteState => ({
  contactName: qouteState.contactName || '',
  customerEmail: qouteState.customerEmail || '',
  customerPhone: qouteState.customerPhone || '',
  currentSpending: qouteState.currentSpending || '',
  exContractExpireDate: qouteState.exContractExpireDate ? new Date(qouteState.exContractExpireDate) : '',
  followUpDate: qouteState.followUpDate ? new Date(qouteState.followUpDate) : '',
});

const customerEditorFormInitialValuesSelector = createSelector(
  getCustomerEditorFormInitialValues,
  identity,
);

export default customerEditorFormInitialValuesSelector;
