import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, fieldInputPropTypes } from 'redux-form';
import { map } from 'lodash-es';
import { isRequired } from '../../utils/services/validator';
import { Select } from '../../core/components';
import { USER_TYPES } from '../constants';

const userTypeOptions = map(USER_TYPES, ({ name, value }) => ({
  label: name,
  value,
}));

class UserTypeSelect extends PureComponent {
  onChange = (event, value) => {
    const { input } = this.props;
    input.onChange(value);
  };

  render() {
    const {
      input: { name },
      selectProps,
    } = this.props;
    return (
      <Field
        name={name}
        component={Select}
        label="User type"
        options={userTypeOptions}
        validate={[isRequired]}
        onChange={this.onChange}
        {...selectProps}
      >
        <option value="" />
      </Field>
    );
  }
}

UserTypeSelect.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  selectProps: PropTypes.object,
};

UserTypeSelect.defaultProps = {
  selectProps: undefined,
};

export default UserTypeSelect;
