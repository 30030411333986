import styled from 'styled-components';
import { mapper, media } from '../../../utils/styles';

const MapContainer = styled.div`
  width: 100%;
  height: ${props => mapper(props.size, { small: '300px', medium: '400px', large: '500px' }, 'auto')};
  min-height: 100%;
  max-height: 100%;

  ${media.tablet`
    height: ${props => mapper(props.size, { small: '200px', medium: '300px', large: '350px' }, 'medium')};
  `}
`;

export default MapContainer;

export const RadioGroupContainer = styled.div`
  display: block;
`;
