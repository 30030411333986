import { keyframes } from 'styled-components';

export const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const zoomIn = keyframes`
  from {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  to {
    transform: none;
  }
`;

export const fadeAndZoomIn = keyframes`
  from {
    opacity: 0;
    transform: scale3d(0.9, 0.9, 0.9);
  }

  to {
    opacity: 1;
    transform: none;
  }
`;

export const zoomOut = keyframes`
  from {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    transform: none;
  }
`;

export const fadeAndZoomOut = keyframes`
  from {
    opacity: 0;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 1;
    transform: none;
  }
`;

export const pulse = keyframes`
  0% {
    transform: none;
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  100% {
    transform: none;
  }
`;

export const fadeAndPulse = keyframes`
  0% {
    opacity: 0;
    transform: none;
  }

  50% {
    opacity: 0.5;
    transform: scale3d(1.05, 1.05, 1.05);
  }

  100% {
    opacity: 1;
    transform: none;
  }
`;

export const slideInRight = (size = 30) => keyframes`
  from { transform: translateX(${-size}px); }
  to { transform: translateX(0px); }
`;

export const slideInUp = keyframes`
  from {
    transform: translate3d(0, 15px, 0);
  }

  to {
    transform: none;
  }
`;

export const fadeAndSlideInUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 15px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
`;

export const slideInDown = keyframes`
  from {
    transform: translate3d(0, -15px, 0);
  }

  to {
    transform: none;
  }
`;

export const fadeAndSlideInDown = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -15px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
`;
