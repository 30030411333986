import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash-es';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import {
  Panel,
  Grid,
  GridColumn,
  Timeline,
  TimelineProgress,
  TimelineProgressBar,
  TimelineEvent,
  TimelineEventIcon,
  TimelineEventDate,
  TimelineEventLabel,
} from '../../../core/components/styled';
import { getLastUrlSelector } from '../../../core/ducks';
import {
  SERVICE_TYPES,
  CONTAINERS_BY_SERVICE_TYPE,
  WASTE_TYPES,
  RECURRING_FREQUENCIES,
} from '../../../common/constants';
import { currency } from '../../../utils/services/formatter';
import { PageHeader, PageTitle, PageBackButton, PageBackButtonIcon } from '../../../common/components/styled';
import {
  OpportunityServiceList,
  OpportunityServiceListHeader,
  OpportunityServiceListTitle,
  OpportunityServiceListSubTitle,
  OpportunityServiceDetails,
  OpportunityServiceDetail,
  OpportunityServiceDetailLabel,
  OpportunityServiceDetailValue,
} from '../styled';
import monthlyServicePrice from '../../../common/services/monthlyServicePrice';

class SoldOpportunityDetailsPage extends PureComponent {
  componentWillUnmount() {}

  render() {
    const { locations, previousPageUrl } = this.props;
    return (
      <>
        <PageHeader>
          <PageTitle>
            <PageBackButton to={previousPageUrl}>
              <PageBackButtonIcon />
            </PageBackButton>
            Status tracker
          </PageTitle>
        </PageHeader>

        <Panel>
          {map(locations, ({ services, businessName, address, ...location }) => (
            <OpportunityServiceList key={location.uid}>
              <OpportunityServiceListHeader>
                <OpportunityServiceListTitle>{businessName}</OpportunityServiceListTitle>
                <OpportunityServiceListSubTitle>{address.line1}</OpportunityServiceListSubTitle>
              </OpportunityServiceListHeader>

              {map(
                services,
                ({
                  serviceType,
                  numberOfContainers,
                  equipmentType,
                  wasteType,
                  recurringFrequency,
                  hasCasters,
                  hasLockbar,
                  price,
                  sold,
                  ...service
                }) => (
                  <OpportunityServiceDetails key={service.uid}>
                    <Grid multiLine>
                      <GridColumn size="2/12" sizePhone="4/12">
                        <OpportunityServiceDetail>
                          <OpportunityServiceDetailLabel>Equipment</OpportunityServiceDetailLabel>
                          <OpportunityServiceDetailValue>
                            {SERVICE_TYPES[serviceType].name}
                          </OpportunityServiceDetailValue>
                        </OpportunityServiceDetail>
                      </GridColumn>
                      <GridColumn size="2/12" sizePhone="4/12">
                        <OpportunityServiceDetail>
                          <OpportunityServiceDetailLabel>No. of Containers</OpportunityServiceDetailLabel>
                          <OpportunityServiceDetailValue>{numberOfContainers}</OpportunityServiceDetailValue>
                        </OpportunityServiceDetail>
                      </GridColumn>

                      <GridColumn size="1/12" sizePhone="4/12">
                        <OpportunityServiceDetail>
                          <OpportunityServiceDetailLabel>Size</OpportunityServiceDetailLabel>
                          <OpportunityServiceDetailValue>
                            {CONTAINERS_BY_SERVICE_TYPE[serviceType][equipmentType].name}
                          </OpportunityServiceDetailValue>
                        </OpportunityServiceDetail>
                      </GridColumn>

                      <GridColumn size="2/12" sizePhone="4/12">
                        <OpportunityServiceDetail>
                          <OpportunityServiceDetailLabel>Material</OpportunityServiceDetailLabel>
                          <OpportunityServiceDetailValue>{WASTE_TYPES[wasteType].name}</OpportunityServiceDetailValue>
                        </OpportunityServiceDetail>
                      </GridColumn>

                      <GridColumn size="2/12" sizePhone="4/12">
                        <OpportunityServiceDetail>
                          <OpportunityServiceDetailLabel>Frequency</OpportunityServiceDetailLabel>
                          <OpportunityServiceDetailValue>
                            {RECURRING_FREQUENCIES[recurringFrequency].name}
                          </OpportunityServiceDetailValue>
                        </OpportunityServiceDetail>
                      </GridColumn>

                      <GridColumn size="2/12" sizePhone="4/12">
                        <OpportunityServiceDetail>
                          <OpportunityServiceDetailLabel>Monthly price</OpportunityServiceDetailLabel>
                          <OpportunityServiceDetailValue>
                            {currency(monthlyServicePrice(price, hasCasters, hasLockbar))}
                          </OpportunityServiceDetailValue>
                        </OpportunityServiceDetail>
                      </GridColumn>
                    </Grid>

                    {sold && (
                      <Timeline margin="medium no">
                        <TimelineProgress>
                          <TimelineProgressBar width={`${sold.timelineProgress}%`} />
                        </TimelineProgress>

                        <TimelineEvent color={sold.purchasedInfo.isReached ? 'primary' : 'grayDark'}>
                          <TimelineEventIcon />
                          <TimelineEventDate>{sold.purchasedInfo.date}</TimelineEventDate>
                          <TimelineEventLabel>Purchase date</TimelineEventLabel>
                        </TimelineEvent>

                        {sold.hasCancelation && (
                          <TimelineEvent color={sold.cancellationInfo.isReached ? 'primary' : 'grayDark'}>
                            <TimelineEventIcon />
                            <TimelineEventDate>{sold.cancellationInfo.date}</TimelineEventDate>
                            <TimelineEventLabel>Cancellation letter </TimelineEventLabel>
                          </TimelineEvent>
                        )}

                        <TimelineEvent color={sold.scheduledInfo.isReached ? 'primary' : 'grayDark'}>
                          <TimelineEventIcon />
                          <TimelineEventDate>{sold.scheduledInfo.date}</TimelineEventDate>
                          <TimelineEventLabel>Scheduled delivery</TimelineEventLabel>
                        </TimelineEvent>

                        <TimelineEvent color={sold.startInfo.isReached ? 'primary' : 'grayDark'}>
                          <TimelineEventIcon />
                          <TimelineEventDate>{sold.startInfo.date}</TimelineEventDate>
                          <TimelineEventLabel>Service Start</TimelineEventLabel>
                        </TimelineEvent>
                      </Timeline>
                    )}
                  </OpportunityServiceDetails>
                ),
              )}
            </OpportunityServiceList>
          ))}
        </Panel>
      </>
    );
  }
}

SoldOpportunityDetailsPage.propTypes = {
  locations: PropTypes.array.isRequired,
  previousPageUrl: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  locations: state.opportunities.opportunity.opportunity.locations,
  previousPageUrl: getLastUrlSelector(state.core, '/opportunities/sold'),
});

const mapDispatchToProps = { push };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SoldOpportunityDetailsPage),
);
