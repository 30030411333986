import styled, { css } from 'styled-components';

const FormError = styled.span`
  display: block;
  margin-top: 4px;
  font-size: 12px;
  color: ${props => props.theme.brandAlert};

  ${props =>
    props.alignRight &&
    css`
      width: 100%;
      text-align:right;
    `}
`;

export default FormError;
