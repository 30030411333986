import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PanelSectionLoading } from '../../../core/components/styled';
import { Resolver } from '../../../core/components';
import { DocumentTitle } from '../../../common/components';
import { getQueryParams } from '../../../utils/services/queryParams';
import { QUOTED } from '../../../quote/constants';
import { loadOpportunities } from '../../ducks';
import QuotedOpportunitiesSection from './QuotedOpportunitiesSection';

class QuotedOpportunitiesSectionResolver extends PureComponent {
  resolve = () => {
    const {
      loadOpportunities,
      location: { search },
    } = this.props;
    const { page, limit, sortedBy, sortOrder } = getQueryParams(search);
    return loadOpportunities(QUOTED, page, limit, sortedBy, sortOrder);
  };

  render() {
    return (
      <>
        <DocumentTitle>Quoted Opportunities</DocumentTitle>
        <Resolver
          successComponent={QuotedOpportunitiesSection}
          loadingComponent={PanelSectionLoading}
          resolve={this.resolve}
        />
      </>
    );
  }
}

QuotedOpportunitiesSectionResolver.propTypes = {
  loadOpportunities: PropTypes.func.isRequired,
  location: RouterPropTypes.location.isRequired,
};

const mapDispatchToProps = { loadOpportunities };

export default withRouter(
  connect(
    undefined,
    mapDispatchToProps,
  )(QuotedOpportunitiesSectionResolver),
);
