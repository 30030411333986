import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { fieldInputPropTypes } from 'redux-form';
import { map, sortBy } from 'lodash-es';
import { ServiceTypeSelect as ServiceTypeSelectContainer } from './styled';
import { SERVICE_TYPES } from '../constants';
import ServiceTypeOption from './ServiceTypeOption';

const serviceTypes = sortBy(map(SERVICE_TYPES, 'value'));

class ServiceTypeSelect extends PureComponent {
  state = {};

  static getDerivedStateFromProps(props) {
    const {
      acceptedServiceTypes,
      input: { value, onChange },
    } = props;

    if (acceptedServiceTypes && acceptedServiceTypes.indexOf(value) === -1) onChange(acceptedServiceTypes[0]);
    return null;
  }

  selectServiceType = serviceType => {
    const { input } = this.props;
    input.onChange(serviceType);
  };

  render() {
    const {
      input: { value },
      acceptedServiceTypes,
      margin,
    } = this.props;

    this.serviceTypes = acceptedServiceTypes || serviceTypes;

    return (
      <ServiceTypeSelectContainer margin={margin}>
        {this.serviceTypes.map(serviceType => (
          <ServiceTypeOption
            type={serviceType}
            onClick={this.selectServiceType}
            isSelected={value === serviceType}
            key={serviceType}
          />
        ))}
      </ServiceTypeSelectContainer>
    );
  }
}

ServiceTypeSelect.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  acceptedServiceTypes: PropTypes.arrayOf(PropTypes.string),
  margin: PropTypes.string,
};

ServiceTypeSelect.defaultProps = {
  acceptedServiceTypes: undefined,
  margin: undefined,
};

export default ServiceTypeSelect;
