import styled, { css } from 'styled-components';
import { BareButtonStyle } from '.';
import { Icon } from '..';
import { sizeMapper, alignMapper, colorMapper, mapper, media } from '../../../utils/styles';

export const TableCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${alignMapper};
  width: ${props => `${props.width}`};
  min-height: 60px;
  padding: 8px 20px 8px 20px;
  word-break: break-word;
  line-height: 18px;
  font-size: 14px;

  ${props =>
    props.padding &&
    css`
      padding: ${props => sizeMapper(props.padding)};
    `};

  ${props =>
    props.smallPadding &&
    css`
      padding: 8px 10px;
    `};

  ${media.tablet` 
    line-height: 16px;
    font-size: 13px;
  `}
`;

export const TableHeadCellSortIcon = styled(Icon).attrs({ icon: 'arrowDown' })`
  position: absolute;
  top: 18px;
  right: 12px;
  display: none;
  width: 12px;
  height: 12px;
  transform: none;
  transition: transform 0.3s ease-out;
`;

export const TableHeadCell = styled(TableCell)`
  position: relative;
  min-height: 45px;
  text-transform: uppercase;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 11px;
  color: ${props => props.theme.grayDarker};
  transition: background-color 0.2s ease-out;

  ${TableHeadCellSortIcon} {
    transform: ${props => mapper(props.sortOrder, { asc: 'none', desc: 'rotate(180deg)' })};
  }

  ${props =>
    props.sortable &&
    css`
      cursor: pointer;

      &:hover {
        ${TableHeadCellSortIcon} {
          display: inline-block;
        }
      }
    `};

  ${props =>
    props.isSortedBy &&
    css`
      background-color: ${props => props.theme.grayLight};

      ${TableHeadCellSortIcon} {
        display: inline-block;
      }
    `};

  ${media.phone` 
    font-size: 11px;
  `}
`;

export const TableActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${alignMapper};
`;

export const TableActionButton = styled.button`
  ${BareButtonStyle};
  margin-right: 15px;
  line-height: 14px;
  font-size: 12px;
  color: ${props => props.theme.grayDark};

  &:hover {
    color: ${props => props.theme.brandPrimary};
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const TableActionIcon = styled(Icon)`
  vertical-align: top;
  width: 13px;
  height: 13px;
`;

export const TableRow = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${props => props.theme.grayLight};
  color: ${colorMapper};

  ${media.tablet`
    width: 980px
  `}
`;

export const TableHead = styled.div`
  width: 100%;
  background-color: ${props => props.theme.grayLighter};

  ${media.tablet`
    width: 980px
  `}
`;

export const TableBody = styled.div`
  ${props =>
    props.withClickableRows &&
    css`
      ${TableRow}:hover {
        background-color: ${props => props.theme.grayLighter};
        cursor: pointer;
      }
    `};
`;

export const Table = styled.div`
  overflow: auto;
  background-color: #fff;
`;
