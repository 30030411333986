import styled, { css } from 'styled-components';
import { BareButtonStyle } from './Button';
import { media } from '../../../utils/styles';

export const Tab = styled.button`
  ${BareButtonStyle};
  padding: 20px 20px;
  border-bottom: 2px solid transparent;
  text-transform: uppercase;

  ${props =>
    props.isSelected &&
    css`
      border-bottom-color: ${props => props.theme.brandPrimary};
    `};

  ${props =>
    props.isDisabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `};

  &.active {
    border-bottom-color: ${props => props.theme.brandPrimary};
    color: ${props => props.theme.brandPrimary};
  }

  ${media.tablet`
    text-transform: none;
    padding: 20px 15px;
  `}

  ${media.phone`
    padding: 20px 10px;
  `}
`;

export const Tabs = styled.div`
  display: flex;
  justify-content: center;
  background-color: #fff;
  border-bottom: 1px solid ${props => props.theme.grayLight};
`;
