import thunk from 'redux-thunk';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import { PRODUCTION } from './core/constants';
import { getEnvironment } from './core/services/environment';
import { reducer as coreReducer } from './core/ducks';
import { reducer as commonReducer } from './common/ducks';
import { reducer as accountReducer } from './account/ducks';
import { reducer as usersReducer } from './users/ducks';
import { reducer as quoteReducer } from './quote/ducks';
import { reducer as opportunitiesReducer } from './opportunities/ducks';
import { reducer as marketConfigurationReducer } from './market/ducks';
import history from './core/services/history';
import gaMiddleware from './core/services/gaMiddleware';

const router = routerMiddleware(history);

/* eslint-disable no-underscore-dangle */
const composeEnhancers = (getEnvironment() !== PRODUCTION && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
/* eslint-enable no-underscore-dangle */

const rootReducer = combineReducers({
  router: routerReducer,
  form: formReducer,
  core: coreReducer,
  common: commonReducer,
  account: accountReducer,
  users: usersReducer,
  quote: quoteReducer,
  opportunities: opportunitiesReducer,
  marketConfiguration: marketConfigurationReducer,
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk, router, gaMiddleware)));

export default store;
