import React from 'react';
import Responsive from 'react-responsive';
import { PHONE_MAX_WIDTH, TABLET_MAX_WIDTH } from '../../core/styles';

const mobileThreshold = PHONE_MAX_WIDTH;
const tabletThreshold = TABLET_MAX_WIDTH;

const Mobile = props => <Responsive {...props} maxWidth={mobileThreshold} />;
const NonMobile = props => <Responsive {...props} minWidth={mobileThreshold + 1} />;
const Tablet = props => <Responsive {...props} maxWidth={tabletThreshold} />;
const NonTablet = props => <Responsive {...props} minWidth={tabletThreshold + 1} />;

export { Mobile, NonMobile, Tablet, NonTablet };
