import styled, { css } from 'styled-components';
import { sizeMapper, alignMapper } from '../../../utils/styles';

const ButtonSet = styled.div`
  display: flex;
  justify-content: space-around;
  margin: ${props => sizeMapper(props.margin, 'medium no no')};
  flex-wrap: wrap;

  ${props =>
    props.align &&
    css`
      justify-content: ${alignMapper(props)};
    `};
`;

export default ButtonSet;
