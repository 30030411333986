import React from 'react';
import PropTypes from 'prop-types';

import { Motion } from 'react-motion';

import useClusterMarkerMemoized from './clusterHooks';
import clusterMarkerStyles from './ClusterMarker.sass';
import { ClusterMarkerStyled } from './ClusterMarkerStyled';

const ClusterMarker = ({
  text,
  styles,
  initialScale,
  defaultScale,
  hoveredScale,
  stiffness,
  damping,
  precision,
  isChecked,
  hovered,
}) => {
  const [defaultMotionStyle, motionStyle = {}] = useClusterMarkerMemoized({
    initialScale,
    defaultScale,
    hoveredScale,
    hovered,
    stiffness,
    damping,
    precision,
  });

  return (
    <Motion defaultStyle={defaultMotionStyle} style={motionStyle}>
      {({ scale }) => (
        <div
          className={styles.marker}
          style={{
            transform: `translate3D(0,0,0) scale(${scale}, ${scale})`,
          }}
        >
          <ClusterMarkerStyled className={styles.text} isChecked={isChecked}>
            {text}
          </ClusterMarkerStyled>
        </div>
      )}
    </Motion>
  );
};

ClusterMarker.propTypes = {
  text: PropTypes.number,
  styles: PropTypes.object,
  initialScale: PropTypes.number,
  defaultScale: PropTypes.number,
  hoveredScale: PropTypes.number,
  hovered: PropTypes.bool,
  stiffness: PropTypes.number,
  damping: PropTypes.number,
  precision: PropTypes.number,
  isChecked: PropTypes.bool,
};

ClusterMarker.defaultProps = {
  text: '0',
  styles: clusterMarkerStyles,
  initialScale: 0.6,
  defaultScale: 1,
  hoveredScale: 1.15,
  hovered: false,
  stiffness: 320,
  damping: 7,
  precision: 0.001,
  isChecked: false,
};

export default ClusterMarker;
