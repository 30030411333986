import { identity } from 'lodash-es';
import { createSelector } from 'reselect';
import { FRONT_LOAD, CART } from '../../common/constants';
import { locationByServiceIdSelector, serviceByIdSelector } from '../ducks';

const getServiceEditorFormInitialValues = (qouteState, locationId, serviceId) => {
  const location = locationByServiceIdSelector(qouteState, serviceId) || qouteState.locations[locationId];
  const service = serviceByIdSelector(qouteState, serviceId) || { serviceType: FRONT_LOAD };

  const { serviceType, ...serviceData } = service;

  const serviceInitialValues = {
    serviceType,
    FrontLoad: serviceType === FRONT_LOAD ? serviceData : undefined,
    Cart: serviceType === CART ? serviceData : undefined,
  };
  return {
    location,
    service: serviceInitialValues,
  };
};

const serviceEditorFormInitialValuesSelector = createSelector(
  getServiceEditorFormInitialValues,
  identity,
);

export default serviceEditorFormInitialValuesSelector;
