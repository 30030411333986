import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import { filter, find } from 'lodash-es';
import { isPartnerSuperAdminSelector } from '../../account/ducks';
import { deleteLocation, deleteService, saveQuote, locationsWithServicesSelector } from '../ducks';
import confirm from '../../core/services/confirm';
import ServiceList from './ServiceList';

class ServiceSummary extends PureComponent {
  addLocation = () => {
    const { quoteId, push } = this.props;
    push(`/quote/${quoteId}/choose-provider`);
  };

  editLocation = locationId => {
    const { quoteId, push } = this.props;
    push(`/quote/${quoteId}/locations/${locationId}`);
  };

  deleteLocation = async locationId => {
    const confirmed = await confirm('Delete location', 'Are you sure you want to delete this location?');
    if (!confirmed) return;

    const { quoteId, locations, deleteLocation, saveQuote, push } = this.props;
    deleteLocation(locationId);
    await saveQuote();

    const restOfQualifiedLocations = filter(
      locations,
      ({ id, isDisqualified }) => id !== locationId && !isDisqualified,
    );

    if (!restOfQualifiedLocations.length) {
      push(`/quote/${quoteId}/choose-provider`);
    } else {
      push(`/quote/${quoteId}/locations/${restOfQualifiedLocations[0].id}/services`);
    }
  };

  addService = locationId => {
    const { quoteId, push } = this.props;
    push(`/quote/${quoteId}/locations/${locationId}/services`);
  };

  editService = serviceId => {
    const { quoteId, push } = this.props;
    push(`/quote/${quoteId}/services/${serviceId}`);
  };

  deleteService = async serviceId => {
    const confirmed = await confirm('Delete service', 'Are you sure you want to delete this service?');
    if (!confirmed) return;

    const { quoteId, locations, selectedServiceId, deleteService, saveQuote, push } = this.props;
    deleteService(serviceId);
    await saveQuote();

    if (serviceId === selectedServiceId) {
      const location = find(locations, location => !!find(location.services, { id: serviceId }));
      push(`/quote/${quoteId}/locations/${location.id}/services`);
    }
  };

  render() {
    const { locations, showPrice, smallCard, isPartnerSuperAdmin, selectedLocationId, selectedServiceId } = this.props;

    return locations.map((location, index) => (
      <ServiceList
        key={location.id}
        locationIndex={index}
        location={location}
        showPrice={showPrice}
        smallCard={smallCard}
        isPartnerSuperAdmin={isPartnerSuperAdmin}
        selectedLocationId={selectedLocationId}
        selectedServiceId={selectedServiceId}
        editLocation={this.editLocation}
        deleteLocation={this.deleteLocation}
        addService={this.addService}
        editService={this.editService}
        deleteService={this.deleteService}
      />
    ));
  }
}

ServiceSummary.propTypes = {
  quoteId: PropTypes.string.isRequired,
  locations: PropTypes.array.isRequired,
  showPrice: PropTypes.bool,
  smallCard: PropTypes.bool,
  isPartnerSuperAdmin: PropTypes.bool.isRequired,
  selectedLocationId: PropTypes.string,
  selectedServiceId: PropTypes.string,
  deleteLocation: PropTypes.func.isRequired,
  deleteService: PropTypes.func.isRequired,
  saveQuote: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
};

ServiceSummary.defaultProps = {
  showPrice: false,
  smallCard: false,
  selectedLocationId: undefined,
  selectedServiceId: undefined,
};

const locationsFilter = { mustHaveAddress: true };

const mapStateToProps = state => ({
  isPartnerSuperAdmin: isPartnerSuperAdminSelector(state.account.login),
  locations: locationsWithServicesSelector(state.quote.quote, locationsFilter),
});

const mapDispatchToProps = {
  deleteLocation,
  deleteService,
  saveQuote,
  push,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ServiceSummary),
);
