import styled, { css } from 'styled-components';
import { sizeMapper } from '../../../utils/styles';

export const ServiceListLocationAddress = styled.div`
  font-size: 16px;
`;

export const ServiceListLocationIndex = styled.span`
  display: block;
  flex-shrink: 0;
  width: 23px;
  height: 23px;
  margin-right: 15px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.brandPrimary};
  text-align: center;
  line-height: 23px;
  font-size: 16px;
  color: ${props => props.theme.brandPrimary};
`;

export const ServiceListLocationError = styled.div`
  margin-top: 6px;
  font-size: 12px;
`;

export const ServiceListLocation = styled.div`
  display: flex;
  align-items: center;

  ${props =>
    props.isDisqualified &&
    css`
      color: ${props => props.theme.brandAlert};

      ${ServiceListLocationIndex} {
        border-color: ${props => props.theme.brandAlert};
        color: ${props => props.theme.brandAlert};
      }
    `};

  ${props =>
    props.isSelected &&
    css`
      ${ServiceListLocationIndex} {
        background-color: ${props => props.theme.brandPrimary};
        color: #fff;
      }
    `};
`;

export const ServiceListHeaderActions = styled.div``;

export const ServiceListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

export const ServiceList = styled.div`
  margin: ${props => sizeMapper(props.margin, 'no no small')};
  padding-bottom: 10px;
  border-bottom: 1px solid ${props => props.theme.grayLight};

  &:last-child {
    border-bottom: none;
  }
`;
