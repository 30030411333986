import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { PanelSection } from '../../../core/components/styled';
import { DocumentTitle } from '../../../common/components';
import { changePassword } from '../../ducks';
import { ExpiredPasswordForm } from '../forms';
import { AccountPanelDescription } from '../styled';

class ResetPasswordPage extends PureComponent {
  onFormSubmit = ({ oldPassword, newPassword }) => {
    const { changePassword, push } = this.props;
    changePassword(oldPassword, newPassword)
      .then(async () => {
        push('/account/login');
      })
      .catch(() => {});
  };

  render() {
    const { isResettingPassword, errorMessage, isChangeingPasswordFailed } = this.props;
    return (
      <>
        <DocumentTitle>Update Password</DocumentTitle>
        <AccountPanelDescription>
          Your password has expired! Please update your password in order to access your account!
        </AccountPanelDescription>
        <PanelSection isLoading={isResettingPassword}>
          <ExpiredPasswordForm
            onSubmit={this.onFormSubmit}
            errorMessage={errorMessage}
            isChangeingPasswordFailed={isChangeingPasswordFailed}
          />
        </PanelSection>
      </>
    );
  }
}

ResetPasswordPage.propTypes = {
  isResettingPassword: PropTypes.bool.isRequired,
  changePassword: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  isChangeingPasswordFailed: PropTypes.any.isRequired,
  errorMessage: PropTypes.any.isRequired,
};

const mapStateToProps = state => ({
  resetPassword: state.account.resetPassword,
  errorMessage: state.account.resetPassword.errorMessage,
  isChangeingPasswordFailed: state.account.resetPassword.isChangeingPasswordFailed,
});

const mapDispatchToProps = {
  changePassword,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPasswordPage);
