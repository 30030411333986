import { parse, stringify } from 'qs';
import { pickBy, assign } from 'lodash-es';
import isFalsy from './isFalsy';

export const getQueryParams = queryString => parse(queryString, { ignoreQueryPrefix: true });
export const setQueryParams = (queryString, params, options = { allowZero: true }) =>
  stringify(pickBy(assign(getQueryParams(queryString), params), value => !isFalsy(value, { ...options })), {
    addQueryPrefix: true,
    encode: true,
  });

export const createUrl = (urlPath, queryString, params, options) =>
  `${urlPath}${setQueryParams(queryString, params, options)}`;
