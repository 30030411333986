import { mapKeys } from 'lodash-es';
import { FRONT_LOAD, CART } from './serviceTypes';

export const WASTE_TYPES = mapKeys(
  [
    { name: 'Waste', value: 'Waste' },
    { name: 'Food Waste', value: 'FW' },
    { name: 'Recycling - General', value: 'SSR' },
    { name: 'Recycling - Cardboard Only', value: 'OCC' },
  ],
  'value',
);

export const WASTE_TYPES_BY_SERVICE_TYPE = {
  [FRONT_LOAD]: ['Waste', 'OCC', 'SSR'],
  [CART]: ['Waste', 'FW', 'SSR'],
};
