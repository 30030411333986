import update from 'immutability-helper';
import { acceptInvitation as doAcceptInvitation } from '../services/account';

// Actions
const START_ACCEPT_INVITATION = 'account/acceptInvitation/START_ACCEPT_INVITATION';
const COMPLETE_ACCEPT_INVITATION = 'account/acceptInvitation/COMPLETE_ACCEPT_INVITATION';
const FAIL_ACCEPT_INVITATION = 'account/acceptInvitation/FAIL_ACCEPT_INVITATION';
const RESET = 'account/acceptInvitation/RESET';

// Initial state
const initialState = {
  isAcceptingInvitation: false,
  isAcceptInvitationFailed: false,
  errorMessage: '',
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_ACCEPT_INVITATION:
      return update(state, {
        $merge: { isAcceptingInvitation: true },
      });

    case COMPLETE_ACCEPT_INVITATION:
      return update(state, {
        $merge: { isAcceptingInvitation: false },
      });

    case FAIL_ACCEPT_INVITATION:
      return update(state, {
        $merge: {
          isAcceptingInvitation: false,
          isAcceptInvitationFailed: true,
          errorMessage: action.errorMessage.response.data.exceptionMessage,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startAcceptInvitation = () => ({
  type: START_ACCEPT_INVITATION,
});

const completeAcceptInvitation = () => ({
  type: COMPLETE_ACCEPT_INVITATION,
});

const failAcceptInvitation = errorMessage => ({
  type: FAIL_ACCEPT_INVITATION,
  errorMessage,
});

export const acceptInvitation = (code, email, password) => dispatch => {
  dispatch(startAcceptInvitation());
  const acceptInvitationPromise = doAcceptInvitation(code, email, password);
  acceptInvitationPromise
    .then(() => dispatch(completeAcceptInvitation()))
    .catch(error => dispatch(failAcceptInvitation(error)));
  return acceptInvitationPromise;
};

export const resetAcceptInvitation = () => ({
  type: RESET,
});
