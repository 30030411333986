import { mapKeys } from 'lodash-es';

const BUSINESS_TYPES = mapKeys(
  [
    { id: 1, name: 'General Business Services / Office Space', qualityCode: 'MSW1' },
    { id: 2, name: 'Hospitals', qualityCode: 'MSW3' },
    { id: 3, name: 'Medical Offices', qualityCode: 'MSW1' },
    { id: 4, name: 'Leisure & Entertainment', qualityCode: 'MSW1' },
    { id: 5, name: 'Hotels & Housing', qualityCode: 'MSW3' },
    { id: 6, name: 'Retail Stores', qualityCode: 'MSW3' },
    { id: 7, name: 'Convenience Stores', qualityCode: 'MSW3' },
    { id: 8, name: 'Food Stores', qualityCode: 'MSW4' },
    { id: 9, name: 'Restaurants', qualityCode: 'MSW4' },
    { id: 10, name: 'Wholesale / Distribution', qualityCode: 'MSW3' },
    { id: 11, name: 'Food Wholesale / Distribution', qualityCode: 'MSW4' },
    { id: 12, name: 'Manufacturing / Industrial Property', qualityCode: 'MSW4' },
    { id: 13, name: 'Food Manufacturing', qualityCode: 'MSW4' },
  ],
  'id',
);

export default BUSINESS_TYPES;
