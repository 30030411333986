import { createGlobalStyle } from 'styled-components';
import { transparentize } from 'polished';
import { theme } from '../../styles';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    overflow-x: auto;
    padding: 0;
    text-rendering: optimizeLegibility;
    background-color: ${theme.bodyBackgroundColor};
    font-family: ${theme.fontFamilyBase};
    font-size: ${theme.fontSizeBase};
    color: ${theme.brandDefault};

  }

  a {
    text-decoration: none;
    color: ${theme.brandPrimary};
  }

  ul,
  ol {
    padding: 0;
  }

  ::selection {
    background-color: ${transparentize(0.8, theme.brandPrimary)};
  }
`;

export default GlobalStyle;
