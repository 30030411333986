import { combineReducers } from 'redux';
import { reducer as serviceOptionsReducer } from './serviceOptions';
import { reducer as addressesReducer } from './addresses';

export { loadAddresses, resetAddresses } from './addresses';

export {
  loadServiceOptions,
  resetServiceOptions,
  serviceTypesSelector,
  frontLoadServiceOptionsSelector,
  cartServiceOptionsSelector,
  serviceOptionsSelector,
  serviceRulesForZipCodeAndFilterSelector,
} from './serviceOptions';

export const reducer = combineReducers({
  addresses: addressesReducer,
  serviceOptions: serviceOptionsReducer,
});
