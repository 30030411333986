import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { fieldInputPropTypes } from 'redux-form';
import { map } from 'lodash-es';
import { SERVICE_PROVIDERS_OPTIONS } from '../constants';
import {
  ServiceProviderSelector as ServiceProviderSelectorContainer,
  ServiceProviderOption,
  ServiceProviderLogo,
  ServiceProviderName,
  ServiceProviderNameInput,
} from './styled';

class ServiceProviderSelector extends PureComponent {
  state = { inputValue: '' };

  static getDerivedStateFromProps(props) {
    const { input } = props;
    const isExistingServiceProvider = !!SERVICE_PROVIDERS_OPTIONS[input.value];
    const inputValue = !isExistingServiceProvider ? input.value : '';

    return {
      isExistingServiceProvider,
      inputValue,
    };
  }

  render() {
    const { inputValue } = this.state;
    const { input } = this.props;

    return (
      <ServiceProviderSelectorContainer>
        {map(SERVICE_PROVIDERS_OPTIONS, serviceProvider => (
          <ServiceProviderOption
            serviceProvider={serviceProvider.id}
            isSelected={input.value === serviceProvider.id}
            onClick={() => input.onChange(serviceProvider.id)}
            key={serviceProvider.id}
          >
            <ServiceProviderLogo />
            <ServiceProviderName>{serviceProvider.name}</ServiceProviderName>
          </ServiceProviderOption>
        ))}

        <ServiceProviderOption>
          <ServiceProviderLogo />
          <ServiceProviderNameInput placeholder="Service Provider Name" value={inputValue} onChange={input.onChange} />
        </ServiceProviderOption>
      </ServiceProviderSelectorContainer>
    );
  }
}

ServiceProviderSelector.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
};

export default ServiceProviderSelector;
