import React from 'react';
import PropTypes from 'prop-types';

import Script from './Script';

const GoogleMapsScript = ({ onCreate, onError, onLoad, children }) => (
  <Script
    name="googleMaps"
    isUrl
    content="https://maps.googleapis.com/maps/api/js?libraries=places&v=3.38&key=AIzaSyBEPAkh2NlU4LSfqi5GxJZYz-saLmk0obs&language=en"
    onCreate={onCreate}
    onError={onError}
    onLoad={onLoad}
  >
    {children}
  </Script>
);

GoogleMapsScript.propTypes = {
  children: PropTypes.node.isRequired,
  onCreate: PropTypes.func,
  onError: PropTypes.func,
  onLoad: PropTypes.func,
};

GoogleMapsScript.defaultProps = {
  onCreate: undefined,
  onError: undefined,
  onLoad: undefined,
};

export default GoogleMapsScript;
