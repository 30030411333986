import { map, castArray } from 'lodash-es';

const transformLoadFranchiseForAddress = values => {
  const addresses = map(castArray(values), ({ line1, zip }) => ({
    standardizedAddress: line1,
    zipCode: zip,
  }));
  return { addresses };
};

export default transformLoadFranchiseForAddress;
