import mobilePageTitles from '../navigation/mobilePageTitles';

const extendedMobilePageTitles = mobilePageTitles.map(x => ({
  regex: new RegExp(x.route.replace(/:[^\s/]+/g, '([\\w-_]+)')),
  ...x,
}));

export default function getMobilePageTitle(currentPath) {
  const matchItem = extendedMobilePageTitles
    .filter(entry => {
      const match = currentPath.match(entry.regex);
      return match && match.index === 0;
    })
    .sort((e1, e2) => e2.route.length - e1.route.length);
  return matchItem.length > 0 ? matchItem[0].title : '';
}
