import http from '../../core/services/http';
import { transformLoadUsers, transformLoadUser } from './transformUser';

const FIRST_PAGE = 1;
const LIMIT_PER_PAGE = 20;

export const createUser = ({ role, companyId, companyName, firstName, lastName, email, phoneNumber }) =>
  http.post('partnerPortal/user', {
    role,
    companyId: Number(companyId),
    companyName,
    firstName,
    lastName,
    email,
    phoneNumber,
    callBackUrl: 'account/accept-invitation',
  });

export const updateUser = ({ userId, role, companyId, firstName, lastName, email, phoneNumber }) =>
  http.put(`partnerPortal/user/${userId}`, {
    role,
    companyId: Number(companyId),
    firstName,
    lastName,
    email,
    phoneNumber,
  });

export const loadUser = userId =>
  http.get(`partnerPortal/user/${userId}`).then(response => transformLoadUser(response.data));

export const loadUsers = (page = FIRST_PAGE, limit = LIMIT_PER_PAGE, sortedBy, sortOrder, searchTerm) =>
  http
    .get('partnerPortal/users', {
      params: {
        page,
        limit,
        sortOrder: sortedBy,
        sortDirection: sortOrder,
        searchUserName: searchTerm,
      },
    })
    .then(response => transformLoadUsers(response.data));

export const deleteUser = userId => http.delete(`partnerPortal/user/${userId}`);
