import update from 'immutability-helper';
import { requestPasswordReset as doRequestPasswordReset } from '../services/account';

// Actions
const START_REQUEST_PASSWORD_RESET = 'account/requestPasswordReset/START_REQUEST_PASSWORD_RESET';
const COMPLETE_REQUEST_PASSWORD_RESET = 'account/requestPasswordReset/COMPLETE_REQUEST_PASSWORD_RESET';
const RESET = 'account/requestPasswordReset/RESET';

// Initial state
const initialState = {
  isRequestingPasswordReset: false,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_REQUEST_PASSWORD_RESET:
      return update(state, { $merge: { isRequestingPasswordReset: true } });

    case COMPLETE_REQUEST_PASSWORD_RESET:
      return update(state, { $merge: { isRequestingPasswordReset: false } });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startRequestPasswordReset = () => ({
  type: START_REQUEST_PASSWORD_RESET,
});

const completeRequestPasswordReset = () => ({
  type: COMPLETE_REQUEST_PASSWORD_RESET,
});

export const requestPasswordReset = email => dispatch => {
  dispatch(startRequestPasswordReset());
  const requestPasswordResetPromise = doRequestPasswordReset(email);
  requestPasswordResetPromise
    .then(() => dispatch(completeRequestPasswordReset()))
    .catch(() => {
      dispatch(completeRequestPasswordReset());
    });
  return requestPasswordResetPromise;
};

export const resetRequestPasswordReset = () => ({
  type: RESET,
});
